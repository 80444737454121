import apiClient from "services/api-client";
import * as actions from "state/actions";

// The maximum age for the working set, in milliseconds
const MAXIMUM_AGE = 1000 * 60 * 60 * 24; // one day

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});

  const reloadActions = ({user, namespaces}) => {
    const timestamp = new Date();
    const currentDomain = getState("config", "currentNamespace", "domain");
    const currentSchemas = (
      getState("resource", "namespaces", "data") || []
    ).flatMap(({schemas}) => schemas.map(({id}) => id));
    const newSchemas = new Set(
      namespaces.flatMap(({schemas}) => schemas.map(({id}) => id))
    );
    return [
      actions.RESOURCE_ADDED.make({
        resource: "user",
        data: user,
        timestamp,
      }),
      actions.RESOURCE_ADDED.make({
        resource: "namespaces",
        data: namespaces,
        timestamp,
      }),
      actions.CONFIG_SET.make({
        currentNamespace:
          namespaces.find((ns) => ns.domain === currentDomain) || namespaces[0],
      }),
      // purge any thing collection linked to a namespace that was
      // removed from the working set
      ...currentSchemas
        .filter((id) => !newSchemas.has(id))
        .map((id) => actions.RESOURCE_PURGED.make({resource: `things(${id})`})),
    ];
  };

  return {
    isOld: () => {
      const oldest = [
        getState("resource", "user", "timestamp"),
        getState("resource", "namespaces", "timestamp"),
      ]
        .filter((ts) => typeof ts !== "undefined")
        .map((ts) => new Date(ts).getTime())
        .sort((a, b) => a - b)[0];
      return typeof oldest === "undefined" || new Date().getTime() - oldest > MAXIMUM_AGE;
    },

    reloadActions,

    reload: () =>
      client
        .get("workingSet", "u/work")
        .then((workingSet) => dispatch(...reloadActions(workingSet))),

    reloadOngoing: () => !!getState("fetch", "ongoing", "workingSet"),
  };
};

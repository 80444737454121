import React from "react";

import * as conf from "conf";

export default () => (
  <div className="text-center">
    <h1 className="display-3 mb-0">
      <i className="fas fa-cubes" />
    </h1>
    <h1 className="mb-0">MOTAS</h1>
    <p>
      <small className="text-muted">Versión {conf.VERSION}</small>
    </p>
  </div>
);

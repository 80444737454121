const pkg = require("../package.json");

const prefix = "REACT_APP_";

const env = (key, absent = null) => {
  const composite = `${prefix}${key}`;
  return Object.keys(process.env).indexOf(composite) === -1
    ? absent
    : process.env[composite];
};

export const API_BASE = env("API_BASE", "/data/");
export const FILES_BASE = env("FILES_BASE", "/file-path/");
export const COMMIT = env("COMMIT");
export const DEBUG = env("DEBUG", "0") === "1";
export const MAX_ROUTE_HISTORY = parseInt(env("MAX_ROUTE_HISTORY", "100"), 10);
export const MAX_SUGGESTIONS = parseInt(env("MAX_SUGGESTIONS", "3"), 10);
export const UPLOAD_TIMEOUT = parseInt(env("UPLOAD_TIMEOUT", "30000"), 10);
export const VERSION = pkg.version;

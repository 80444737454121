import React from "react";

import {CONFIG_SET} from "state/actions";

export default ({store, schemas, currentSchema, label, title}) => (
  <nav className="schema-tabs">
    {schemas.map((schema) => (
      <button
        type="button"
        key={`schema-${schema.id}`}
        title={title(schema)}
        className={["tab", currentSchema?.id === schema.id ? "active" : null]
          .filter((c) => c)
          .join(" ")}
        onClick={CONFIG_SET.dispatcher(store, {currentSchema: schema})}
      >
        {label(schema)}
      </button>
    ))}
  </nav>
);

import React from "react";

import {Share} from "components/svg-icons";
import ToggleSwitch from "components/ToggleSwitch";
import thingService from "services/things";
import {TOAST_SHOWN, MODAL_SHOWN, MODAL_HIDDEN} from "state/actions";

export default ({store, thing, modalKey}) => {
  const service = thingService(store);
  // initialize the component just with the thing's public attribute
  const shareRef = React.useRef();
  React.useEffect(() => {
    if (shareRef.current) {
      shareRef.current.checked = thing.public;
    }
  }, [thing.public]);
  // grab a ref for the link holder
  const shareLinkRef = React.useRef();
  // react to toggles and modal requests
  const showSharingError = () =>
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "Hubo un problema para compartir el recurso; inténtelo más tarde",
      timeout: 5000,
    });
  const toggleSharing = () => {
    if (shareRef.current && shareRef.current.checked !== thing.public) {
      service.publicToggle(thing, shareRef.current.checked).catch(showSharingError);
    }
  };
  const modalOpen = store.getState("modal", modalKey);
  const modalRef = React.useRef();
  const shareLink = `https://motas.cl/public?s=${thing.schemaId}&t=${thing.id}`;
  const share = () => {
    let promise = Promise.resolve();
    if (shareRef.current && !shareRef.current.checked) {
      shareRef.current.checked = true;
      promise = service.publicToggle(thing, true);
    }
    promise.then(() => store.dispatch(MODAL_SHOWN.make(modalKey)), showSharingError);
  };

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mt-2">
        <label className="mr-1" htmlFor="sharing">
          <strong>Compartir</strong>
        </label>
        <ToggleSwitch
          id="sharing"
          innerRef={shareRef}
          onChange={toggleSharing}
          disabled={service.publicToggleOngoing(thing)}
        />

        <button
          type="button"
          className={[
            "btn btn-sm btn-dark",
            "d-flex align-items-center justify-content-center",
            "ml-1 mb-2 px-1",
          ].join(" ")}
          title="Compartir"
          onClick={share}
          disabled={service.publicToggleOngoing(thing)}
        >
          <Share />
        </button>
      </div>
      <div
        className={["modal", modalOpen ? "fade show d-block" : null]
          .filter((c) => c)
          .join(" ")}
        tabIndex="-1"
        role="dialog"
        ref={modalRef}
        onClick={(e) =>
          e.target === modalRef.current ? MODAL_HIDDEN.dispatch(store, modalKey) : null
        }
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Compartir</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-end">
              <input
                type="text"
                className="form-control form-control-lg"
                readOnly={true}
                value={shareLink}
                ref={shareLinkRef}
                onClick={(e) => e.target.select()}
              />
              <button
                type="button"
                className="btn btn-lg btn-link"
                onClick={() => {
                  shareLinkRef.current.select();
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(shareLink);
                  } else if (document.execCommand) {
                    document.execCommand("copy");
                  } else {
                    console.error("Couldn't copy link", shareLink);
                  }
                }}
              >
                Copiar
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </>
  );
};

import React from "react";

import {Switch, Case} from "components/layout/Switch";
import PluralField from "components/thing/fields/PluralField";
import * as fmt from "formatters";
import {isDefined} from "utils";

const TypedNumberField = ({inputId, initialState, saveState, prop}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.value = initialState;
      pending.current = false;
    }
  });

  // Handle changes made to the input
  const setNumericValue = (event) => {
    const raw = event.target.valueAsNumber;
    const valid = event.target.validity.valid;
    if (valid && !isNaN(raw)) {
      saveState(raw);
    } else if (valid) {
      saveState(null);
    }
  };
  const restoreNumericValue = () => {
    input.current.value = initialState;
  };

  let placeholder = prop.propName;
  if (isDefined(prop.minValue) && isDefined(prop.maxValue)) {
    placeholder += ` (entre ${prop.minValue} y ${prop.maxValue})`;
  } else if (isDefined(prop.minValue)) {
    placeholder += ` (mínimo ${prop.minValue})`;
  } else if (isDefined(prop.maxValue)) {
    placeholder += ` (máximo ${prop.maxValue})`;
  }
  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <input
        type="number"
        step="any"
        {...(isDefined(prop.minValue) ? {min: prop.minValue} : {})}
        {...(isDefined(prop.maxValue) ? {max: prop.maxValue} : {})}
        lang="es"
        id={inputId}
        className="form-control"
        required={prop.required}
        placeholder={placeholder}
        onChange={setNumericValue}
        onBlur={restoreNumericValue}
        ref={input}
      />
      {prop.numberFormat && (
        <small className="form-text text-muted">
          {isDefined(initialState)
            ? fmt.formatNumber(initialState, prop.numberFormat)
            : ""}
        </small>
      )}
    </>
  );
};

const RestrictedNumberField = ({inputId, initialState, saveState, prop}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.value = isDefined(initialState) ? initialState.toString() : "";
      pending.current = false;
    }
  });

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <select
        id={inputId}
        className="form-control"
        required={prop.required}
        onChange={(evt) =>
          saveState(evt.target.value !== "" ? parseFloat(evt.target.value) : null)
        }
        ref={input}
      >
        <option value="">Seleccionar {prop.propName.toLowerCase()}</option>
        {prop.restrictedChoices.map((v, index) => (
          <option key={`choice-${index}`} value={v.toString()}>
            {prop.numberFormat ? fmt.formatNumber(v, prop.numberFormat) : v.toString()}
          </option>
        ))}
      </select>
    </>
  );
};

const NumberField = ({prop, ...props}) => (
  <Switch
    variable={Boolean(prop.restrictedChoices)}
    defaultCase={TypedNumberField}
    prop={prop}
    {...props}
  >
    <Case value={false} component={TypedNumberField} />
    <Case value={true} component={RestrictedNumberField} />
  </Switch>
);

export default (props) => <PluralField component={NumberField} {...props} />;

import AsyncComponent from "components/AsyncComponent";

export default class Form extends AsyncComponent {
  controls = {};

  bindControl(property) {
    return (ref) => {
      this.controls = {
        ...this.controls,
        [property]: ref,
      };
      if (ref) {
        ref.value = this.state[property] || "";
      }
    };
  }
}

import namespaces from "services/namespaces";

export default ({getState, dispatch}) => {
  const namespaceService = namespaces({getState, dispatch});

  const schemas = () => {
    const ns = namespaceService.currentNamespace();
    const thingSchemas = ns.schemas || [];
    const hasFileSchema =
      ns.domain &&
      (thingSchemas.length === 0 ||
        thingSchemas.some((s) => s.contents.thingProps.some((p) => p.type === "file")));
    return [
      ...thingSchemas,
      ...(hasFileSchema ? [{id: `__files_for_${ns.domain}__`, files: true}] : []),
    ];
  };

  return {
    schemas,

    currentSchema: () => {
      const options = schemas();
      const saved = getState("config", "currentSchema");
      return !saved ? options[0] : options.find(({id}) => saved.id === id) || options[0];
    },

    schemaTabLabel: (schema) =>
      schema.files
        ? "Archivos"
        : schema.contents.thingNameTab ??
          schema.contents.thingNamePlural ??
          `${schema.contents.thingName}s`,

    schemaTabTitle: (schema) =>
      schema.files
        ? "Archivos"
        : schema.contents.thingNamePlural ?? `${schema.contents.thingName}s`,
  };
};

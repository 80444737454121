import React from "react";

import PluralField from "components/thing/fields/PluralField";
import ToggleSwitch from "components/ToggleSwitch";

const BooleanField = ({inputId, initialState, saveState, prop}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.checked = Boolean(initialState);
      pending.current = false;
    }
  });

  return (
    <div className="d-flex justify-content-between align-items-start">
      <label htmlFor={inputId}>
        <strong>{prop.propName}</strong>
      </label>
      <ToggleSwitch
        id={inputId}
        onChange={(evt) => saveState(evt.target.checked)}
        innerRef={input}
      />
    </div>
  );
};

export default (props) => <PluralField component={BooleanField} {...props} />;

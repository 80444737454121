import React from "react";

import Loader from "components/Loader";
import PluralField from "components/thing/fields/PluralField";
import thingsService from "services/things";
import namespacesService from "services/namespaces";
import {addThing} from "services/views";
import {TOAST_SHOWN, MODAL_SHOWN, MODAL_HIDDEN} from "state/actions";
import * as fmt from "formatters";
import {isDefined} from "utils";

const RelationField = ({inputId, initialState, saveState, formStateKey, prop, store}) => {
  const modalKey = `${inputId}:modal`;
  const modalOpen = store.getState("modal", modalKey);
  const modalRef = React.useRef();
  const [search, setSearch] = React.useState("");
  const service = thingsService(store);
  const linkedSchema = React.useMemo(
    () =>
      namespacesService(store)
        .currentNamespace()
        .schemas.find(({contents: {name}}) => name === prop.relationTarget),
    // eslint-disable-next-line
    [prop.relationTarget],
  );
  const complete =
    isDefined(linkedSchema) &&
    (search.trim().length === 0 || !service.searchIncomplete());
  const loading = isDefined(linkedSchema) && service.searchOngoing();
  const loadMoreDisabled = complete || loading;

  const searchInput = React.useRef();
  React.useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = search;
    }
    // eslint-disable-next-line
  }, [searchInput]);
  React.useEffect(() => {
    if (search.trim().length > 0) {
      service.search(search);
    }
    return () => service.clearSearch();
    // eslint-disable-next-line
  }, [search]);

  const handleLoadError = () =>
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: `No se pudieron cargar ${prop.propName.toLowerCase()}; inténtelo más tarde`,
      timeout: 5000,
    });

  if (!isDefined(linkedSchema)) {
    return (
      <span className="text-muted">
        No es posible cargar {prop.propName.toLowerCase()}
      </span>
    );
  }

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <div className="d-flex flex-row">
        <button
          type="button"
          id={inputId}
          className={[
            "btn btn-block relation-field-selector text-truncate mr-2",
            initialState ? "text-left" : "text-center",
          ].join(" ")}
          title={`Seleccionar ${prop.propName.toLowerCase()}`}
          onClick={MODAL_SHOWN.dispatcher(store, modalKey)}
        >
          {initialState
            ? fmt.formatThing(namespacesService(store).currentNamespace())(initialState)
            : `Seleccionar ${prop.propName.toLowerCase()}`}
        </button>
        <button
          type="button"
          className="btn btn-light filefield-button"
          title={`Quitar ${prop.propName.toLowerCase()}`}
          onClick={() => saveState(null)}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </button>
      </div>
      <div
        className={["modal", modalOpen ? "fade show d-block" : null]
          .filter((c) => c)
          .join(" ")}
        tabIndex="-1"
        role="dialog"
        ref={modalRef}
        onClick={(e) =>
          e.target === modalRef.current ? MODAL_HIDDEN.dispatch(store, modalKey) : null
        }
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <form
                className="w-100"
                onSubmit={(evt) => {
                  setSearch(searchInput.current.value);
                  evt.preventDefault();
                  evt.stopPropagation();
                  return false;
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Buscar ${prop.propName.toLowerCase()}...`}
                  ref={searchInput}
                />
              </form>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loading && <Loader />}
              {/* Display the things available to lookup */}
              {(search.trim().length === 0
                ? []
                : store.getState("resource", service.searchKey(), "data", "items") ?? []
              )
                .filter((thing) => service.thingMatches(linkedSchema, thing, search))
                .map((thing, index, arr) => (
                  <div className="thing-item px-0" key={`thing-${thing.id}`}>
                    <button
                      type="button"
                      className={[
                        "btn",
                        "btn-block",
                        "text-truncate",
                        index === 0 ? "first" : null,
                        index === arr.length - 1 ? "last" : null,
                      ]
                        .filter((c) => c)
                        .join(" ")}
                      title={fmt.formatThing(namespacesService(store).currentNamespace())(
                        thing,
                      )}
                      onClick={() => {
                        saveState(thing);
                        MODAL_HIDDEN.dispatch(store, modalKey);
                      }}
                    >
                      {fmt.formatThing(namespacesService(store).currentNamespace())(
                        thing,
                      )}
                    </button>
                  </div>
                ))}
              {/* Show a "load more" button, or a warning that no files exist */}
              {search.trim().length === 0 ? (
                <p className="lead mt-1 text-center">
                  Busque{" "}
                  {(
                    linkedSchema.contents.thingNamePlural ||
                    `${linkedSchema.contents.thingName}s`
                  ).toLowerCase()}
                  .
                </p>
              ) : (
                <button
                  type="button"
                  className="btn btn-light btn-block btn-lg"
                  disabled={loadMoreDisabled}
                  onClick={() => service.search(search).catch(handleLoadError)}
                >
                  {complete ? "—" : "Cargar más"}
                </button>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={() => {
                  MODAL_HIDDEN.dispatch(store, modalKey);
                  if (isDefined(linkedSchema)) {
                    addThing(store, linkedSchema, [formStateKey, prop.key]);
                  }
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </>
  );
};

export default (props) => <PluralField component={RelationField} {...props} />;

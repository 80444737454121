import * as errors from "errors";
import {API_BASE, UPLOAD_TIMEOUT} from "conf";
import fetch from "services/fetch";
import XMLHttpRequest from "services/XMLHttpRequest";
import * as actions from "state/actions";

export default ({getState, dispatch}) => {
  // Build a good-enough set of minimal headers.
  const headers = () => {
    const token = getState("auth", "token");
    const namespace =
      getState("config", "currentNamespace") ||
      getState("resource", "namespaces", "data", 0) ||
      null;
    return {
      "Content-Type": "application/json",
      ...(namespace ? {"Namespace-Domain": namespace.domain} : {}),
      ...(token ? {Authorization: `Token ${token}`} : {}),
    };
  };

  // Build a URL against the base API from a path and query parameters.
  const buildURL = (path, params) => {
    const urlparams = params
      ? Object.entries(params)
          .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
          .join("&")
      : null;
    return [
      API_BASE,
      path.startsWith("/") ? path.slice(1) : path,
      ...(urlparams !== null ? ["?", urlparams] : []),
    ].join("");
  };

  // Main method for all JSON-exchange endpoints
  const method =
    (method) =>
    (resource, path, config = {}) => {
      const options = {
        headers: {...(config.headers || {}), ...headers()},
        ...(config.body ? {body: JSON.stringify(config.body)} : {}),
        credentials: "omit",
        method,
      };
      const url = buildURL(path, config.params);

      dispatch(actions.FETCH_ONGOING.make({resource}));
      return fetch(url, options)
        .catch(() => {
          dispatch(actions.FETCH_FINISHED.make({resource, timestamp: new Date()}));
          return Promise.reject(new errors.NetworkError());
        })
        .then((response) => {
          dispatch(actions.FETCH_FINISHED.make({resource, timestamp: new Date()}));
          return response.json().then(
            (body) => ({response, body}),
            () => ({response, body: null}),
          );
        })
        .then(({response, body}) => {
          if (response.ok) {
            return body ?? {};
          }
          if (response.status === 400) {
            return Promise.reject(new errors.RequestError(body));
          }
          if (response.status === 401) {
            if (body?.context?.refreshToken) {
              dispatch(actions.TOKEN_SOFT_EXPIRED.make());
            } else {
              dispatch(actions.TOKEN_HARD_EXPIRED.make());
            }
            return Promise.reject(new errors.AuthenticationError(body));
          }
          if (response.status === 403) {
            return Promise.reject(new errors.ForbiddenError());
          }
          if (response.status === 404) {
            return Promise.reject(new errors.NotFoundError(body));
          }
          return Promise.reject(new errors.ServerError());
        });
    };

  // POST a File object (within config under 'file'), as a raw blob.
  // Use XMLHttpRequest for now, since this was ported from motas-android.
  const postBlob = (resource, path, config = {}) => {
    if (!config.file) {
      throw new Error("config.file is missing");
    }
    let resolver;
    let rejecter;
    const promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });
    const request = new XMLHttpRequest();
    request.open("POST", buildURL(path, config.params));
    const requestHeaders = {
      ...(config.headers || {}),
      ...headers(),
      "Content-Type": "application/octet-stream",
    };
    Object.entries(requestHeaders).forEach(([h, v]) => request.setRequestHeader(h, v));

    request.responseType = "json";
    request.timeout = UPLOAD_TIMEOUT;
    request.ontimeout = () => {
      dispatch(actions.FETCH_FINISHED.make({resource, timestamp: new Date()}));
      return rejecter(
        new errors.TimeoutError(`while uploading file ${config.file.name}`),
      );
    };
    request.onerror = () => {
      dispatch(actions.FETCH_FINISHED.make({resource, timestamp: new Date()}));
      return rejecter(new errors.ServerError(`while uploading file ${config.file.name}`));
    };
    request.onload = () => {
      dispatch(actions.FETCH_FINISHED.make({resource, timestamp: new Date()}));
      if (request.status === 400) {
        return rejecter(new errors.RequestError(request.response));
      }
      if (request.status === 401) {
        if (request.response && request.response.context.refreshToken) {
          dispatch(actions.TOKEN_SOFT_EXPIRED.make());
        } else {
          dispatch(actions.TOKEN_HARD_EXPIRED.make());
        }
        return rejecter(new errors.AuthenticationError(request.response));
      }
      if (request.status === 413) {
        return rejecter(
          new errors.BodyTooLargeError(`while uploading file ${config.file.name}`),
        );
      }
      if (request.status === 200 || request.status === 201) {
        return resolver(request.response);
      }
      return rejecter(new errors.ServerError());
    };
    dispatch(actions.FETCH_ONGOING.make({resource}));
    request.send(config.file);
    return promise;
  };

  return {
    headers,
    buildURL,
    get: method("GET"),
    post: method("POST"),
    put: method("PUT"),
    patch: method("PATCH"),
    delete: method("DELETE"),
    postBlob,
  };
};

import React from "react";

const DEFAULT_TIMEOUT = 2000;

export default class Toast extends React.Component {
  componentDidMount() {
    if (this.props.shown) {
      this.setupTimer();
    }
  }

  componentDidUpdate() {
    if (this.props.shown) {
      this.setupTimer();
    }
  }

  setupTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.timer = null;
      this.props.hide();
    }, this.props.timeout || DEFAULT_TIMEOUT);
  }

  renderIcon(icon) {
    switch (icon) {
      case "success":
        return <i className="fas fa-check" />;
      case "danger":
        return <i className="fas fa-fire" />;
      case "warning":
        return <i className="fas fa-exclamation-triangle" />;
      case "info":
        return <i className="fas fa-info-circle" />;
      default:
        return null;
    }
  }

  render() {
    const {shown, text, icon} = this.props;
    if (!shown) {
      return null;
    }
    return (
      <div className={`toast-message alert alert-${icon}`} role="alert">
        {this.renderIcon(icon)}
        {text}
      </div>
    );
  }
}

import React from "react";

import Form from "components/Form";
import * as errors from "errors";
import profile from "services/profile";
import {TOAST_SHOWN, VIEW_POPPED} from "state/actions";

const pristineState = {
  missingOldPassword: false,
  missingNewPassword: false,
  missingConfirmation: false,
  passwordMismatch: false,
  incorrectOldPassword: false,
  passwordTooShort: false,
  passwordMinLength: null,
  networkError: false,
  serverError: false,
  oldPassword: "",
  newPassword: "",
  confirmation: "",
};

export default class PasswordForm extends Form {
  state = {
    ...pristineState,
  };

  changePassword(e) {
    e.preventDefault();
    const {store} = this.props;
    const oldPassword = this.controls.oldPassword.value || "";
    const newPassword = this.controls.newPassword.value || "";
    const confirmation = this.controls.confirmation.value || "";
    if (!oldPassword || !newPassword || !confirmation) {
      return this.setState({
        ...pristineState,
        oldPassword,
        newPassword,
        confirmation,
        missingOldPassword: !oldPassword,
        missingNewPassword: !newPassword,
        missingConfirmation: !confirmation,
      });
    }
    if (newPassword !== confirmation) {
      return this.setState({
        ...pristineState,
        oldPassword,
        passwordMismatch: true,
      });
    }
    this.setState({
      ...pristineState,
      oldPassword,
      newPassword,
      confirmation,
    });
    profile(store)
      .changePassword(oldPassword, newPassword)
      .then(this.async(this.handleSuccess), this.async(this.handleError));
  }

  handleSuccess() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "info",
      text: "Se cambió la contraseña",
    });
    VIEW_POPPED.dispatch(store);
  }

  handleError(error) {
    if (error instanceof errors.NetworkError) {
      this.setState({networkError: true});
    } else if (error instanceof errors.RequestError && error.details.context.tooShort) {
      this.setState({
        newPassword: "",
        confirmation: "",
        passwordTooShort: true,
        passwordMinLength: error.details.context.minLength,
      });
    } else if (
      error instanceof errors.RequestError &&
      error.details.context.incorrectOldPassword
    ) {
      this.setState({oldPassword: "", incorrectOldPassword: true});
    } else {
      this.setState({serverError: true});
    }
  }

  render() {
    const formDisabled = profile(this.props.store).changePasswordOngoing();
    return (
      <form
        className="px-3 flex-grow-1 overflow-auto w-lg-50 w-md-75"
        disabled={formDisabled}
        onSubmit={this.changePassword.bind(this)}
      >
        <div className="form-group">
          <label htmlFor="oldPassword">
            <strong>Contraseña actual</strong>
          </label>
          <input
            className="form-control"
            type="password"
            name="oldPassword"
            id="oldPassword"
            placeholder="Contraseña actual"
            ref={this.bindControl("oldPassword").bind(this)}
            disabled={formDisabled}
          />
          {this.state.missingOldPassword && (
            <p className="form-text text-danger">Debes ingresar tu contraseña actual</p>
          )}
          {this.state.incorrectOldPassword && (
            <p className="form-text text-danger">La contraseña actual es incorrecta</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">
            <strong>Nueva contraseña</strong>
          </label>
          <input
            className="form-control"
            type="password"
            name="newPassword"
            id="newPassword"
            placeholder="Nueva contraseña"
            ref={this.bindControl("newPassword").bind(this)}
            disabled={formDisabled}
          />
          {this.state.missingNewPassword && (
            <p className="form-text text-danger">Debes ingresar una nueva contraseña</p>
          )}
          {this.state.passwordTooShort && (
            <p className="form-text text-danger">
              La contraseña debe tener al menos {this.state.passwordMinLength} caracteres
            </p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="confirmation">
            <strong>Repertir nueva contraseña</strong>
          </label>
          <input
            className="form-control"
            type="password"
            name="confirmation"
            id="confirmation"
            placeholder="Repetir nueva contraseña"
            ref={this.bindControl("confirmation").bind(this)}
            disabled={formDisabled}
          />
          {this.state.missingConfirmation && (
            <p className="form-text text-danger">Debes repetir la nueva contraseña</p>
          )}
          {this.state.passwordMismatch && (
            <p className="form-text text-danger">Las contraseñas no coinciden</p>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block"
          disabled={formDisabled}
        >
          CAMBIAR
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-lg btn-block"
          disabled={formDisabled}
          onClick={VIEW_POPPED.dispatcher(this.props.store)}
        >
          VOLVER
        </button>
        {this.state.networkError && (
          <p className="form-text text-danger">
            No se pudo establecer una conexión con el servidor
          </p>
        )}
        {this.state.serverError && (
          <p className="form-text text-danger">
            Ocurrió un error inesperado; inténtalo más tarde
          </p>
        )}
      </form>
    );
  }
}

export class NetworkError extends Error {}

export class BaseRequestError extends Error {}

export class RequestError extends BaseRequestError {
  constructor(details) {
    super();
    this.details = details;
  }
}

export class AuthenticationError extends BaseRequestError {
  constructor(details) {
    super();
    this.details = details;
  }
}

export class ForbiddenError extends BaseRequestError {}

export class NotFoundError extends BaseRequestError {
  constructor(details) {
    super();
    this.details = details;
  }
}

export class TimeoutError extends BaseRequestError {}

export class BodyTooLargeError extends BaseRequestError {}

export class ServerError extends BaseRequestError {}

import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "css/index.css";
import * as conf from "conf";
import App from "components/App";
import * as serviceWorker from "serviceWorker";
import {setupPersistentStore} from "state";
import * as actions from "state/actions";
import {fromPairs} from "utils";

const store = setupPersistentStore(
  `state-${conf.VERSION}`,
  [
    "auth.token",
    "router.view",
    "resource._changeCounter",
    "config.currentNamespace.domain",
  ],
  (state) => fromPairs(["auth", "router", "config", "resource"].map((k) => [k, state[k]]))
);
ReactDOM.render(<App store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can
// change unregister() to register() below. Note this comes with some
// pitfalls.  Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (conf.DEBUG) {
  window.actions = actions;
  window.conf = conf;
  window.store = store;
} else {
  window.version = `${conf.VERSION} (${conf.COMMIT})`;
}

const action = (() => {
  let actionCount = 0;
  return () => {
    const type = `action#${++actionCount}`;
    return {
      type,
      make: (payload) => ({type, payload}),
      dispatch: ({dispatch}, payload) => dispatch({type, payload}),
      dispatcher: ({dispatch}, payload) => () => dispatch({type, payload}),
    };
  };
})();

// General

export const NOOP = action();

// Fetch requests

export const FETCH_ONGOING = action();

export const FETCH_FINISHED = action();

// Resource housekeeping

export const RESOURCE_ADDED = action();

export const RESOURCE_PURGED = action();

// Routing

export const VIEW_PUSHED = action();

export const VIEW_POPPED = action();

export const VIEW_RESET = action();

// Arbitrary configuration

export const CONFIG_SET = action();

export const CONFIG_UNSET = action();

export const CONFIG_CLEAR = action();

// Authentication

export const TOKEN_RECEIVED = action();

export const TOKEN_SOFT_EXPIRED = action();

export const TOKEN_HARD_EXPIRED = action();

// Toast

export const TOAST_SHOWN = action();

export const TOAST_HIDDEN = action();

// Form states

export const FORM_STATE_SAVED = action();

export const FORM_STATE_PURGED = action();

export const FORM_STATE_PURGED_ALL = action();

// Modal state

export const MODAL_SHOWN = action();

export const MODAL_HIDDEN = action();

export const MODAL_HIDDEN_ALL = action();

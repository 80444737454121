import moment from "moment";
import "moment/locale/es";

import {property, fromPairs} from "./utils";
import * as fmt from "./formatters";

const groupKeys = {
  $year: (t) => `${new Date(t).getFullYear()}`,
  $month: (t) => new Date(t).toJSON().slice(0, 7),
  $week: (t) => {
    const m = moment(t);
    const week = m.week();
    const month = m.month() + 1;
    let year = m.year();
    if (week === 1 && month === 11) {
      year += 1;
    }
    return [year, week].join("-");
  },
  $identity: (t) => t,
  $constant: () => 0,
};

const groupTitles = {
  $year: (t) => `${new Date(t).getFullYear()}`,
  $month: (t) => moment(t).locale("es").format("MMMM YYYY"),
  $week: (t) => {
    const m = moment(t);
    const week = m.week();
    const month = m.month() + 1;
    let year = m.year();
    if (week === 1 && month === 11) {
      year += 1;
    }
    return `${year} semana ${week}`;
  },
  $identity: (t) => t.toString(),
  $constant: () => "Totales",
};

export const injectionPositions = (sections, schema) => {
  const {thingSorter, thingAggregator} = schema.contents;
  const keyFn = property(...(thingSorter || ["updatedAt"]));
  const sliceFn = groupKeys[thingAggregator.groupKey] || groupKeys.$identity;
  return [0].concat(
    sections
      .map(({items}) => items[0])
      .map((item) => sliceFn(keyFn(item)))
      .map((sliceKey, index, arr) => {
        if (index === arr.length - 1) {
          return null;
        }
        if (sliceKey !== arr[index + 1]) {
          return index + 1;
        }
        return null;
      })
      .filter((i) => i !== null)
  );
};

const reducers = {
  $noop: (x) => x,
  $flatten: (col) => col.flat(),
  $sum: (arr) => (arr || []).filter((e) => e !== null).reduce((acc, e) => acc + e, 0),
  $max: (arr) => Math.max(...arr),
  $min: (arr) => Math.min(...arr),
  $length: (arr) => arr.length,
  $average: (arr) => {
    let count = 0;
    let sum = 0;
    (arr || []).forEach((e) => {
      if (e !== null) {
        sum += e;
        count += 1;
      }
    });
    return count === 0 ? 0 : sum / count;
  },
  $timelapseHours: (seconds) => {
    const d = moment.duration(Math.abs(seconds), "seconds");
    if (d < 60) {
      return "< 1 minuto";
    }
    const hours = d.asHours();
    if (hours < 1) {
      const minutes = Math.trunc(d.asMinutes());
      if (minutes > 1) {
        return `${minutes} minutos`;
      }
      return "1 minuto";
    }
    const minutes = Math.trunc((hours - Math.trunc(hours)) * 60);
    const hourPart = Math.trunc(hours) > 1 ? `${Math.trunc(hours)} horas` : "1 hora";
    const minutePart =
      minutes === 0 ? "" : minutes > 1 ? ` ${minutes} minutos` : " 1 minuto";
    return `${hourPart}${minutePart}`;
  },
  $money: (x) =>
    x === null
      ? "$ 0"
      : fmt.formatNumber(x, {
          prefix: "$ ",
          suffix: "",
          thousands: true,
          decimals: 0,
        }),
};

const mapOperators = {
  $diff: (a, b) => (a !== null && b !== null ? a - b : 0),
  $add: (...args) => reducers.$sum(args),
  $sum: (arr) => reducers.$sum(arr),
  $times: (a, b) => a * b,
  $div: (a, b, error) => (b === 0 ? error : a / b),
  $concat: (...args) => args.join(""),
  $time: (t) => new Date(t).getTime() / 1000,
  $max: (...args) => Math.max(...args),
  $min: (...args) => Math.min(...args),

  $length: (arr) => arr.length,
  $maximum: (arr) => Math.max(...arr),
  $minimum: (arr) => Math.min(...arr),
  $first: (arr) => arr[0],
  $last: (arr) => arr[arr.length - 1],

  $positive: (v) => (v > 0 ? v : null),
  $not: (v) => !v,
  $isnull: (v) => v === null,
  $null: () => null,
  $iszero: (v) => v === 0,
  $and: (...args) => args.every((x) => x),
  $or: (...args) => args.some((x) => x),
  $eq: (a, ...args) => args.every((x) => x === a),
  $gt: (a, b) => a > b,
  $gte: (a, b) => a >= b,
  $lt: (a, b) => a < b,
  $lte: (a, b) => a <= b,

  $map: (fn, arr) => arr.map((x) => fn(x)),
  $filter: (fn, arr) => arr.filter((x) => fn(x)),
  $reduce: (fn, arr, initial) => arr.reduce((acc, x) => fn(acc, x), initial),
  $call: (fn, ...args) => fn(...args),
  $apply: (fn, args) => fn(...args),
  $partial: (fn, ...args) => (...rest) => fn(...[...args, ...rest]),
  $maximumBy: (fn, arr) =>
    arr
      .map((x) => [x, fn(x)])
      .reduce(([acc, accKey], [item, key]) =>
        key > accKey ? [item, key] : [acc, accKey]
      )[0],
  $minimumBy: (fn, arr) =>
    arr
      .map((x) => [x, fn(x)])
      .reduce(([acc, accKey], [item, key]) =>
        key < accKey ? [item, key] : [acc, accKey]
      )[0],
};

const splitArgs = (string) => {
  const parts = [];
  let nest = 0;
  let prev = 0;
  for (let c = 0; c < string.length; c++) {
    const ch = string[c];
    if (ch === "(") {
      nest += 1;
    } else if (ch === ")") {
      nest -= 1;
    }
    if (ch === "," && nest === 0) {
      parts.push(string.slice(prev, c));
      prev = c + 1;
    } else if (c === string.length - 1) {
      parts.push(string.slice(prev));
    }
  }
  return parts.filter((s) => s !== "");
};

const evaluateMapFn = (expr) => {
  const argStart = expr.indexOf("(");
  if (argStart === -1) {
    return (x) => x;
  }
  const fn = expr.slice(0, argStart);
  const args = expr.slice(argStart);
  if (!fn.startsWith("$")) {
    return (x) => x;
  }
  const argslist = splitArgs(args.slice(1, -1)).map((a) => a.trim());
  if (fn === "$prop") {
    return (x) => {
      const v = property("data", argslist[0])(x);
      return typeof v === "undefined" || v === null ? null : v;
    };
  }
  if (fn === "$number") {
    return () => parseFloat(argslist[0]);
  }
  if (fn === "$fn") {
    return () => mapOperators[argslist[0]] || ((x) => x);
  }
  const nested = argslist.map(evaluateMapFn);
  if (fn === "$if") {
    return (x) => (nested[0](x) ? nested[1](x) : nested[2](x));
  }
  const current = mapOperators[fn] || ((x) => x);
  return (x) => current(...nested.map((n) => n(x)));
};

export const mapFn = (() => {
  const cache = {};
  const maxCached = 10;
  const cachedKeys = [];
  return (spec) => {
    if (cache[spec]) {
      return cache[spec];
    }
    if (!spec.startsWith("eval:")) {
      return property("data", spec);
    }
    cachedKeys.push(spec);
    if (cachedKeys.length > maxCached) {
      delete cache[cachedKeys.shift()];
    }
    const expression = spec.slice("eval:".length);
    cache[spec] = evaluateMapFn(expression);
    return cache[spec];
  };
})();

export const makeAggregates = (slice, schema) => {
  const {
    thingProps,
    thingSorter,
    thingAggregator: {groupKey, aggregates},
  } = schema.contents;
  const keyFn = property(...(thingSorter || ["updatedAt"]));
  const keyedProps = fromPairs(thingProps.map((prop) => [prop.key, prop]));
  const aggSectionKey = `aggregate-${groupKeys[groupKey](keyFn(slice[0]))}`;
  return [
    {
      title: (groupTitles[groupKey] || groupTitles.$identity)(keyFn(slice[0])),
      key: aggSectionKey,
      items: aggregates.map(({label, propKey, reduction, reductionType}, aggIndex) => {
        let data = slice.map(mapFn(propKey));
        reduction.forEach((reductor) => {
          data = (reducers[reductor] || reducers.$noop)(data);
        });
        const prop = keyedProps[propKey];
        const propType = reductionType || (prop ? prop.type : null);
        if (prop && propType === "number" && prop.numberFormat) {
          data = fmt.formatNumber(data, prop.numberFormat);
        } else if (prop && propType === "date") {
          data = fmt.formatDate(data, prop.dateFormat);
        } else if (prop && propType === "datetime") {
          data = fmt.formatDatetime(data, prop.datetimeFormat);
        } else if (prop && propType === "boolean") {
          data = data ? "Sí" : "No";
        } else {
          data = data.toString();
        }
        return {
          id: `${aggSectionKey}-${aggIndex}`,
          aggregate: true,
          label,
          value: data,
        };
      }),
    },
  ];
};

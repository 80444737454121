import React from "react";

import AsyncComponent from "components/AsyncComponent";
import Loader from "components/Loader";
import SplashBanner from "components/splash/SplashBanner";
import things from "services/things";
import {editThing} from "services/views";
import {TOAST_SHOWN} from "state/actions";
import * as fmt from "formatters";

export default class SearchResults extends AsyncComponent {
  get service() {
    return things(this.props.store);
  }

  get isSearching() {
    return this.service.searchOngoing();
  }

  edit(thing) {
    return () =>
      editThing(
        this.props.store,
        this.props.namespace.schemas.find(({id}) => id === thing.schemaId),
        thing
      );
  }

  handleError() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "Hubo un problema para obtener más resultados; inténtelo más tarde",
      timeout: 5000,
    });
  }

  renderFooter() {
    const complete = !this.service.searchIncomplete();
    const disabled = complete || this.isSearching;
    return (
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light btn-block btn-lg"
          disabled={disabled}
          onClick={() => {
            const phrase = this.props.store.getState(
              "resource",
              this.service.searchKey(),
              "data",
              "phrase"
            );
            this.service.search(phrase).catch(this.async(this.handleError));
          }}
        >
          {complete ? "—" : "Cargar más"}
        </button>
      </div>
    );
  }

  renderThing(thing, isFirst, isLast) {
    const formatted = fmt.formatThing(this.props.namespace)(thing);
    const {
      contents: {thingName},
    } = this.props.namespace.schemas.find(({id}) => id === thing.schemaId) || {
      contents: {thingName: "<Desconocido>"},
    };
    return (
      <div
        className={["thing-item", isFirst ? "mt-1" : null].filter((c) => c).join(" ")}
        key={`thing-${thing.id}`}
      >
        <button
          type="button"
          className={[
            "btn",
            "btn-block",
            isFirst ? "first" : null,
            isLast ? "last" : null,
          ]
            .filter((c) => c)
            .join(" ")}
          title={formatted}
          onClick={this.edit(thing).bind(this)}
        >
          <span className="text-truncate">
            <strong>{thingName}: </strong> {formatted}
          </span>
        </button>
      </div>
    );
  }

  renderResults({phrase, total, items}) {
    if (total === 0) {
      return <p className="lead mt-1 text-center">No se encontraron resultados.</p>;
    }
    return (
      <>
        {items.map((item, index) =>
          this.renderThing(item, index === 0, index === items.length - 1)
        )}
        {this.renderFooter()}
      </>
    );
  }

  render() {
    const resource = this.props.store.getState(
      "resource",
      this.service.searchKey(),
      "data"
    );
    return (
      <>
        {this.isSearching && <Loader />}
        {resource ? this.renderResults(resource) : <SplashBanner />}
      </>
    );
  }
}

import apiClient from "services/api";
import namespaces from "services/namespaces";
import * as actions from "state/actions";
import * as errors from "errors";
import * as fmt from "formatters";
import {property, sorter, reverseSorter, mergeItems, fromPairs, isDefined} from "utils";

// The maximum age for the head of a collection of things, in
// milliseconds
const MAXIMUM_AGE = 1000 * 60 * 60 * 8; // 8 hours

// Generate a sorting key function to use when applying a sorter
const sortKey = (schema) => (thing) => {
  const {contents} = schema;
  if (contents.thingSorter && contents.thingSorter.length > 0) {
    const mapFn = property(...contents.thingSorter);
    if (contents.thingSorter[0] === "data" && contents.thingSorter.length === 2) {
      const prop = contents.thingProps.find(({key}) => key === contents.thingSorter[1]);
      switch (prop?.type) {
        case "text":
          return (mapFn(thing) || "").trim().toUpperCase()[0] || "?";
        case "number":
          return mapFn(thing).toString()[0] || "?";
        case "date":
        case "datetime":
          return fmt.datestring(mapFn(thing));
        case "boolean":
          return mapFn(thing) ? "t" : "f";
        default:
          break;
      }
    } else if (
      contents.thingSorter[0] === "createdAt" ||
      contents.thingSorter[0] === "updatedAt"
    ) {
      return fmt.datestring(mapFn(thing));
    }
  }
  return fmt.datestring(thing.updatedAt);
};

// Generate a comparison function for use in Array.prototype.sort()
// given a schema
const makeSorter = (schema) => {
  const {contents} = schema;
  const compare = contents.thingSortDirection
    ? contents.thingSortDirection === "asc"
      ? sorter
      : reverseSorter
    : reverseSorter;
  return compare(sortKey(schema));
};

// Generate a normalized text given a string and a natural language it
// may be written in
const normalizeSearchText = (() => {
  const atilde = /á/gi;
  const etilde = /é/gi;
  const itilde = /í/gi;
  const otilde = /ó/gi;
  const utilde = /ú/gi;
  return (text, language) =>
    text
      .split(" ")
      .filter((t) => t)
      .map((t) =>
        t
          .replace(atilde, "a")
          .replace(etilde, "e")
          .replace(itilde, "i")
          .replace(otilde, "o")
          .replace(utilde, "u"),
      )
      .join(" ")
      .toLowerCase();
})();

// Transforms the given thing so that any references to the target are
// changed with the replacement
const updateReferencesInThing = (thing, schema, target, replacement) =>
  (schema?.contents?.thingProps ?? []).some((prop) => prop.type === "relation")
    ? {
        ...thing,
        data: Object.assign(
          {},
          thing.data,
          fromPairs(
            (schema.contents?.thingProps ?? [])
              .filter((prop) => prop.type === "relation")
              .map((prop) => {
                if (prop.singular && thing.data[prop.key]?.id === target.id) {
                  return [prop.key, replacement];
                } else if (
                  !prop.singular &&
                  (thing.data[prop.key] ?? []).some(({id}) => id === target.id)
                ) {
                  return [
                    prop.key,
                    thing.data[prop.key]
                      .map((placedThing) =>
                        placedThing.id === target.id ? replacement : placedThing,
                      )
                      .filter(isDefined),
                  ];
                } else {
                  return null;
                }
              })
              .filter(isDefined),
          ),
        ),
      }
    : thing;

// Normalizes the representation of a thing given a server-responded
// structure.
const normalizeThing = (schema) => (thing) => {
  if (!isDefined(schema)) {
    return thing;
  }
  const {
    contents: {thingProps},
  } = schema;
  return {
    ...thing,
    data: Object.assign(
      {},
      thing.data,
      // fix broken links in relations
      fromPairs(
        thingProps
          .filter(({type}) => type === "relation")
          .map((prop) => ({prop, value: thing.data[prop.key]}))
          .map(({prop, value}) =>
            prop.singular
              ? [
                  prop.key,
                  isDefined(value) ? (isDefined(value.data) ? value : null) : null,
                ]
              : [
                  prop.key,
                  (value ?? [])
                    .map((innerValue) =>
                      isDefined(innerValue)
                        ? isDefined(innerValue.data)
                          ? innerValue
                          : null
                        : null,
                    )
                    .filter(isDefined),
                ],
          ),
      ),
    ),
  };
};

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});
  const namespaceService = namespaces({getState, dispatch});

  const resourceKey = (schema) => `things(${schema.id})`;
  const searchKey = () => `search(${namespaceService.currentNamespace().domain ?? ""})`;
  const publicToggleKey = (thing) => `publicToggle(${thing.id})`;
  const deleteKey = (thing) => `deleteThing(${thing.id})`;
  const createKey = (schema) => `createThing(${schema.id})`;
  const updateKey = (thing) => `createThing(${thing.id})`;

  // Updates any references found within the store to the given thing
  // with the updates applied, by using the appropriate actions for
  // each case.
  const updateThingReferences = (thing, updates) => {
    const schemas = namespaceService.currentNamespace().schemas ?? [];
    // lookup in thing list
    const resources = Object.entries(getState("resource")).filter(([k]) =>
      /things\(/.test(k),
    );
    // lookup in search list
    const searched = getState("resource", searchKey());
    // lookup in side panel
    const sidePanelStack = getState("config", "sidePanel");
    // assemble actions for each collection/resource
    const requiredActions = [
      ...resources.map(([k, resource]) =>
        actions.RESOURCE_ADDED.make({
          resource: k,
          data: {
            total: resource.data.total,
            items: resource.data.items.map((t) =>
              t.id === thing.id
                ? {...thing, ...updates}
                : updateReferencesInThing(
                    t,
                    schemas.find(({id}) => id === t.schemaId),
                    thing,
                    {...thing, ...updates},
                  ),
            ),
          },
          timestamp: resource.timestamp,
        }),
      ),
      searched
        ? actions.RESOURCE_ADDED.make({
            resource: searchKey(),
            data: {
              phrase: searched.data.phrase,
              total: searched.data.total,
              items: searched.data.items.map((t) =>
                t.id === thing.id
                  ? {...thing, ...updates}
                  : updateReferencesInThing(
                      t,
                      schemas.find(({id}) => id === t.schemaId),
                      thing,
                      {...thing, ...updates},
                    ),
              ),
            },
            timestamp: searched.timestamp,
          })
        : null,
      sidePanelStack
        ? actions.CONFIG_SET.make({
            sidePanel: sidePanelStack.map((spec) =>
              spec.action === "edit-thing" && spec.thing.id === thing.id
                ? {...spec, thing: {...thing, ...updates}}
                : spec.action === "edit-thing"
                  ? {
                      ...spec,
                      thing: updateReferencesInThing(
                        spec.thing,
                        schemas.find(({id}) => id === spec.schemaId),
                        thing,
                        {...thing, ...updates},
                      ),
                    }
                  : spec,
            ),
          })
        : null,
    ].filter((a) => a !== null);
    // dispatch if any action is needed
    if (requiredActions.length > 0) {
      dispatch(...requiredActions);
    }
  };

  // Deletes any references found within the store to the given thing,
  // by using the appropriate actions for each case.
  const deleteThingReferences = (thing) => {
    const schemas = namespaceService.currentNamespace().schemas ?? [];
    // lookup in thing list
    const resources = Object.entries(getState("resource")).filter(([k]) =>
      /things\(/.test(k),
    );
    // lookup in search list
    const searched = getState("resource", searchKey());
    // lookup in side panel
    const sidePanelStack = getState("config", "sidePanel");
    // assemble actions for each collection/resource
    const requiredActions = [
      ...resources.map(([k, resource]) =>
        actions.RESOURCE_ADDED.make({
          resource: k,
          data: {
            total:
              k === resourceKey({id: thing.schemaId})
                ? resource.data.total - 1
                : resource.data.total,
            items: resource.data.items
              .filter((t) => t.id !== thing.id)
              .map((t) =>
                updateReferencesInThing(
                  t,
                  schemas.find(({id}) => id === t.schemaId),
                  thing,
                  null,
                ),
              ),
          },
          timestamp: resource.timestamp,
        }),
      ),
      searched
        ? actions.RESOURCE_ADDED.make({
            resource: searchKey(),
            data: {
              phrase: searched.data.phrase,
              total: searched.data.total,
              items: searched.data.items
                .filter((t) => t.id !== thing.id)
                .map((t) =>
                  updateReferencesInThing(
                    t,
                    schemas.find(({id}) => id === t.schemaId),
                    thing,
                    null,
                  ),
                ),
            },
            timestamp: searched.timestamp,
          })
        : null,
      sidePanelStack
        ? actions.CONFIG_SET.make({
            sidePanel: sidePanelStack
              .filter(
                (spec) => spec.action !== "edit-thing" || spec.thing.id !== thing.id,
              )
              .map((spec) =>
                spec.action === "edit-thing"
                  ? {
                      ...spec,
                      thing: updateReferencesInThing(
                        spec.thing,
                        schemas.find(({id}) => id === spec.schemaId),
                        thing,
                        null,
                      ),
                    }
                  : spec,
              ),
          })
        : null,
    ].filter((a) => a !== null);
    // dispatch if any action is needed
    if (requiredActions.length > 0) {
      dispatch(...requiredActions);
    }
  };

  return {
    resourceKey,

    isOld: (schema) => {
      const ts = getState("resource", resourceKey(schema), "timestamp");
      return (
        typeof ts === "undefined" ||
        new Date().getTime() - new Date(ts).getTime() > MAXIMUM_AGE
      );
    },

    isIncomplete: (schema) => {
      const total = getState("resource", resourceKey(schema), "data", "total");
      return (
        typeof total === "undefined" ||
        total >
          (getState("resource", resourceKey(schema), "data", "items", "length") || 0)
      );
    },

    sortKey,

    forceFetch: (schema) => {
      const sort = makeSorter(schema);
      return client.get(resourceKey(schema), `s/${schema.id}/t`).then(({total, items}) =>
        dispatch(
          actions.RESOURCE_ADDED.make({
            resource: resourceKey(schema),
            data: {total, items: items.map(normalizeThing(schema)).sort(sort)},
            timestamp: new Date(),
          }),
        ),
      );
    },

    fetch: (schema, givenOffset) => {
      const offset =
        typeof givenOffset === "undefined"
          ? getState("resource", resourceKey(schema), "data", "items", "length") || 0
          : givenOffset;
      const sort = makeSorter(schema);
      return client
        .get(resourceKey(schema), `s/${schema.id}/t`, {params: {offset}})
        .then(({total, items}) => {
          const currentResource = getState("resource", resourceKey(schema));
          return dispatch(
            actions.RESOURCE_ADDED.make({
              resource: resourceKey(schema),
              data: {
                total,
                items: mergeItems(
                  currentResource?.data?.items ?? [],
                  items.map(normalizeThing(schema)),
                ).sort(sort),
              },
              timestamp: currentResource?.timestamp ?? new Date(),
            }),
          );
        });
    },

    anyFetchOngoing: (schema) => !!getState("fetch", "ongoing", resourceKey(schema)),

    thingMatches: (schema, thing, phrase) => {
      const normalizedPhrase = normalizeSearchText(phrase, schema.language);
      return schema.contents.thingProps
        .flatMap((prop) =>
          prop.singular
            ? [{prop, value: thing.data[prop.key]}]
            : (thing.data[prop.key] ?? []).map((value) => ({prop, value})),
        )
        .some(({prop, value}) => {
          if (!isDefined(value)) {
            return false;
          }
          switch (prop.type) {
            case "text":
              return (
                normalizeSearchText(value, schema.language).indexOf(normalizedPhrase) !==
                -1
              );
            case "number":
              return (
                fmt.formatNumber(value, prop.numberFormat).indexOf(normalizedPhrase) !==
                -1
              );
            case "date":
              return fmt.datestring(value).indexOf(normalizedPhrase);
            case "datetime":
              return fmt.datestring(value).indexOf(normalizedPhrase);
            default:
              return false;
          }
        });
    },

    searchKey,

    searchIncomplete: () => {
      const total = getState("resource", searchKey(), "data", "total");
      return (
        typeof total === "undefined" ||
        total > (getState("resource", searchKey(), "data", "items", "length") || 0)
      );
    },

    search: (phrase, givenOffset) => {
      const offset =
        typeof givenOffset === "undefined"
          ? getState("resource", searchKey(), "data", "items", "length") || 0
          : givenOffset;
      const schemas = fromPairs(
        (namespaceService.currentNamespace().schemas ?? []).map((schema) => [
          schema.id,
          schema,
        ]),
      );
      return client
        .get(searchKey(), "q", {params: {phrase, offset}})
        .then(({total, items}) => {
          const resourceKey = searchKey();
          const currentResource = getState("resource", resourceKey);
          return dispatch(
            actions.RESOURCE_ADDED.make({
              resource: resourceKey,
              data: {
                phrase,
                total,
                items: mergeItems(
                  currentResource?.data?.items ?? [],
                  (items ?? []).map((thing) =>
                    normalizeThing(schemas[thing.schemaId])(thing),
                  ),
                ),
              },
              timestamp: new Date(),
            }),
          );
        });
    },

    clearSearch: () => dispatch(actions.RESOURCE_PURGED.make({resource: searchKey()})),

    searchOngoing: () => !!getState("fetch", "ongoing", searchKey()),

    publicToggleKey,

    publicToggle: (thing, exposed) =>
      client
        .post(
          publicToggleKey(thing),
          `s/${thing.schemaId}/t/${thing.id}/${exposed ? "expose" : "hide"}`,
        )
        .then(({acknowledged}) =>
          acknowledged ? updateThingReferences(thing, {public: exposed}) : null,
        ),

    publicToggleOngoing: (thing) =>
      !!getState("fetch", "ongoing", publicToggleKey(thing)),

    // Thing delete, create update operations

    deleteKey,

    deleteThing: (thing) =>
      client
        .delete(deleteKey(thing), `s/${thing.schemaId}/t/${thing.id}`)
        .catch((err) => {
          if (err instanceof errors.NotFoundError) {
            return Promise.resolve({acknowledged: true});
          } else {
            return Promise.reject(err);
          }
        })
        .then(({acknowledged}) => {
          if (!acknowledged) {
            return Promise.reject(
              new Error(`deletion of thing ${thing.id} wasn't acknowledged`),
            );
          }
          deleteThingReferences(thing);
          return Promise.resolve({acknowledged});
        }),

    deleteThingReferences,

    deleteOngoing: (thing) => !!getState("fetch", "ongoing", deleteKey(thing)),

    createKey,

    createThing: (schema, props) =>
      client.post(createKey(schema), `s/${schema.id}/t`, {body: props}).then((thing) => {
        const sort = makeSorter(schema);
        const currentResource = getState("resource", resourceKey(schema));
        dispatch(
          actions.RESOURCE_ADDED.make({
            resource: resourceKey(schema),
            data: {
              total: (currentResource?.data?.total ?? 0) + 1,
              items: mergeItems(currentResource?.data?.items ?? [], [
                normalizeThing(schema)(thing),
              ]).sort(sort),
            },
            timestamp: currentResource?.timestamp ?? new Date(),
          }),
        );
        return thing;
      }),

    createOngoing: (schema) => !!getState("fetch", "ongoing", createKey(schema)),

    updateKey,

    updateThing: (thing, updates) =>
      client
        .patch(updateKey(thing), `s/${thing.schemaId}/t/${thing.id}`, {body: updates})
        .then((updatedThing) => {
          updateThingReferences(
            updatedThing,
            normalizeThing(
              namespaceService
                .currentNamespace()
                .schemas.find(({id}) => id === thing.schemaId),
            )(updatedThing),
          );
          return updatedThing;
        }),

    updateThingReferences,

    updateOngoing: (thing) => !!getState("fetch", "ongoing", updateKey(thing)),
  };
};

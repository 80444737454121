export const chain = (obj, ...props) =>
  props.reduce((acc, prop) => (acc || {})[prop], obj);

export const property = (...props) => (obj) => chain(obj, ...props);

export const fromPairs = (pairs) => pairs.reduce((acc, [k, v]) => ({...acc, [k]: v}), {});

export const sorter = (fn) => (a, b) => {
  const mapFn = typeof fn === "string" ? (obj) => obj[fn] : fn;
  const aVal = mapFn(a);
  const bVal = mapFn(b);
  return aVal < bVal ? -1 : aVal === bVal ? 0 : 1;
};

export const reverseSorter = (fn) => (a, b) => 0 - sorter(fn)(a, b);

export const groupBy = (collection, mapFn) => {
  const fn = typeof mapFn === "string" ? (obj) => obj[mapFn] : mapFn;
  return collection.reduce((groups, item) => {
    if (groups.length === 0) {
      return [[item]];
    }
    const lastGroup = groups[groups.length - 1];
    if (fn(lastGroup[lastGroup.length - 1]) === fn(item)) {
      return [...groups.slice(0, -1), [...lastGroup, item]];
    }
    return [...groups, [item]];
  }, []);
};

// Extend a collection, removing duplicates
export const mergeItems = (base, extension, keyFn) => {
  const key = keyFn ?? property("id");
  const baseCatalog = fromPairs(base.map((obj) => [key(obj), obj]));
  const extensionCatalog = fromPairs(extension.map((obj) => [key(obj), obj]));
  return [
    ...base.map((obj) => extensionCatalog[key(obj)] || obj),
    ...extension.filter((obj) => typeof baseCatalog[key(obj)] === "undefined"),
  ];
};

export const isDefined = (x) => typeof x !== "undefined" && x !== null;

export const isUndefined = (x) => !isDefined(x);

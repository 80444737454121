import React from "react";

// Source: https://proto.io/freebies/onoff/
export default ({id, name, checked, disabled, onChange, innerRef}) => (
  <div className="onoffswitch">
    <input
      type="checkbox"
      name={name}
      className="onoffswitch-checkbox"
      id={id}
      tabIndex="0"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      ref={innerRef}
    />
    <label className="onoffswitch-label" htmlFor={id}>
      <span className="onoffswitch-inner"></span>
      <span className="onoffswitch-switch"></span>
    </label>
  </div>
);

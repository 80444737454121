import React from "react";

import {Switch, Case} from "components/layout/Switch";
import NamespaceConfig from "components/namespace/NamespaceConfig";
import NamespaceView from "components/namespace/NamespaceView";
import ProfileForm from "components/profile/ProfileForm";
import PasswordForm from "components/profile/PasswordForm";

export default (props) => {
  return (
    <main
      className={[
        "d-flex",
        "flex-column",
        "flex-grow-1",
        "overflow-hidden",
        "position-relative",
        "h-100",
      ].join(" ")}
    >
      <Switch variable={props.route.view} defaultCase={NamespaceView} {...props}>
        <Case value="profile" component={ProfileForm} />
        <Case value="password" component={PasswordForm} />
        <Case value="namespace-config" component={NamespaceConfig} />
      </Switch>
    </main>
  );
};

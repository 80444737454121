import {NOOP} from "state/actions";
import {createStore} from "state/store";
import {rootReducer} from "state/reducers";
import {saveState, readState} from "state/persistence";

export const setupStore = (initialState) => {
  const store = createStore(rootReducer, initialState);
  store.dispatch(NOOP.make());
  return store;
};

export const setupPersistentStore = (persistenceKey, persistenceProps, mapFn) => {
  const store = createStore(rootReducer, readState(persistenceKey));
  store.dispatch(NOOP.make());
  store.subscribeForProps(persistenceProps, (state) =>
    saveState(persistenceKey, (mapFn || ((s) => s))(state))
  );
  return store;
};

import * as errors from "errors";
import apiClient from "services/api-client";
import auth from "services/auth";

export default (store) => {
  const client = apiClient(store);
  const {refresh} = auth(store);

  const method = (method) => (...args) =>
    client[method](...args).catch((error) =>
      error instanceof errors.AuthenticationError && error.details.context.refreshToken
        ? refresh().then(() => client[method](...args))
        : Promise.reject(error)
    );

  return {
    get: method("get"),
    post: method("post"),
    put: method("put"),
    patch: method("patch"),
    delete: method("delete"),
    postBlob: method("postBlob"),
  };
};

import React from "react";

import {ArrowClockwise} from "components/svg-icons";

export default ({onClick, disabled}) => (
  <div className="refresh-button">
    <button
      type="button"
      className="btn btn-light btn-lg icon-button"
      onClick={onClick}
      title="Actualizar"
      disabled={disabled}
    >
      <ArrowClockwise />
    </button>
  </div>
);

import React from "react";

import PluralField from "components/thing/fields/PluralField";
import * as fmt from "formatters";
import {isDefined} from "utils";

const normalizeDate = (d, t) => new Date(`${d}T${t}`).toJSON();

const DateTimeField = ({inputId, initialState, saveState, prop}) => {
  const inputDate = React.useRef();
  const inputTime = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (inputDate.current && inputTime.current && pending.current) {
      inputDate.current.value = initialState
        ? fmt.formatMachineDate(new Date(initialState))
        : null;
      inputTime.current.value = initialState
        ? fmt.formatMachineTime(new Date(initialState))
        : null;
      pending.current = false;
    }
  });

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <div className="d-flex flex-fill">
        <input
          type="date"
          className="form-control mr-1"
          required={prop.required}
          onChange={(evt) => {
            const value = evt.target.value;
            const time = inputTime.current.value;
            if (!value) {
              saveState(null);
            } else if (!time) {
              inputTime.current.value = "12:00";
              saveState(normalizeDate(value, inputTime.current.value));
            } else {
              saveState(normalizeDate(value, time));
            }
          }}
          ref={inputDate}
        />
        <input
          type="time"
          id={inputId}
          className="form-control ml-1"
          required={prop.required}
          step={60}
          onChange={(evt) => {
            const value = evt.target.value;
            const date = inputDate.current.value;
            if (!value) {
              saveState(null);
            } else if (!date) {
              inputDate.current.value = fmt.formatMachineDate(new Date());
              saveState(normalizeDate(inputDate.current.value, value));
            } else {
              saveState(normalizeDate(date, value));
            }
          }}
          ref={inputTime}
        />
      </div>
      <small className="form-text text-muted">
        {isDefined(initialState)
          ? fmt.formatDatetime(initialState, prop.datetimeFormat)
          : ""}
      </small>
    </>
  );
};

export default (props) => <PluralField component={DateTimeField} {...props} />;

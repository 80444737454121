import * as conf from "conf";
import * as actions from "state/actions";
import {makeReducer, combineReducers} from "state/store";
import {fromPairs} from "utils";

export const authReducer = makeReducer(
  {},
  {
    [actions.TOKEN_RECEIVED.type]: (_, token) => ({expired: false, token}),
    [actions.TOKEN_SOFT_EXPIRED.type]: (s) => ({...s, expired: true}),
    [actions.TOKEN_HARD_EXPIRED.type]: {},
  }
);

export const routerReducer = makeReducer(
  {},
  {
    [actions.VIEW_PUSHED.type]: (s, {view, args}) => ({
      stack: [{view, args}, ...(s.stack || [])].slice(0, conf.MAX_ROUTE_HISTORY),
      view,
      args,
    }),
    [actions.VIEW_POPPED.type]: (s) => ({
      stack: (s.stack || []).slice(1),
      ...((s.stack || []).slice(1)[0] || {}),
    }),
    [actions.VIEW_RESET.type]: {},
  }
);

export const configReducer = makeReducer(
  {},
  {
    [actions.CONFIG_SET.type]: (s, conf) => ({
      ...s,
      ...conf,
    }),
    [actions.CONFIG_UNSET.type]: (s, key) =>
      fromPairs(Object.entries(s || {}).filter(([k]) => k !== key)),
    [actions.CONFIG_CLEAR.type]: {},
  }
);

export const fetchReducer = makeReducer(
  {},
  {
    [actions.FETCH_ONGOING.type]: (s, {resource}) => ({
      ...s,
      ongoing: {...(s.ongoing || {}), [resource]: true},
    }),
    [actions.FETCH_FINISHED.type]: (s, {resource, timestamp}) => ({
      ...s,
      ongoing: fromPairs(Object.entries(s.ongoing || {}).filter(([k]) => k !== resource)),
      lastFetched: typeof timestamp === "string" ? timestamp : timestamp.toJSON(),
    }),
  }
);

export const resourceReducer = makeReducer(
  {},
  {
    [actions.RESOURCE_ADDED.type]: (s, {resource, data, timestamp}) => ({
      ...s,
      [resource]: {
        data,
        timestamp: typeof timestamp === "string" ? timestamp : timestamp.toJSON(),
      },
      _changeCounter: ((s._changeCounter || 0) + 1) % 1000,
    }),
    [actions.RESOURCE_PURGED.type]: (s, {resource, except}) => ({
      ...fromPairs(
        Object.entries(s).filter(
          ([k]) => k !== resource && (typeof except === "undefined" || k === except)
        )
      ),
      _changeCounter: ((s._changeCounter || 0) + 1) % 1000,
    }),
  }
);

export const toastReducer = makeReducer(
  {shown: false},
  {
    [actions.TOAST_SHOWN.type]: (_, {text, icon, timeout}) => ({
      shown: true,
      text,
      icon,
      timeout,
    }),
    [actions.TOAST_HIDDEN.type]: {shown: false},
  }
);

export const formReducer = makeReducer(
  {},
  {
    [actions.FORM_STATE_SAVED.type]: (s, {key, value}) => ({
      ...s,
      [key]: value,
    }),
    [actions.FORM_STATE_PURGED.type]: (s, {key}) =>
      fromPairs(Object.entries(s).filter(([k]) => k !== key)),
    [actions.FORM_STATE_PURGED_ALL.type]: {},
  }
);

export const modalReducer = makeReducer(
  {},
  {
    [actions.MODAL_SHOWN.type]: (s, key) => ({...s, [key]: true}),
    [actions.MODAL_HIDDEN.type]: (s, key) =>
      fromPairs(Object.entries(s).filter(([k]) => k !== key)),
    [actions.MODAL_HIDDEN_ALL.type]: {},
  }
);

export const rootReducer = combineReducers({
  auth: authReducer,
  router: routerReducer,
  config: configReducer,
  fetch: fetchReducer,
  resource: resourceReducer,
  toast: toastReducer,
  form: formReducer,
  modal: modalReducer,
});

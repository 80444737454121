import React from "react";

import Loader from "components/Loader";
import PluralField from "components/thing/fields/PluralField";
import FileDisplay from "components/file/FileDisplay";
import filesService from "services/files";
import {TOAST_SHOWN, MODAL_SHOWN, MODAL_HIDDEN} from "state/actions";
import * as errors from "errors";
import {isDefined} from "utils";

const FileField = ({inputId, pristineState, initialState, saveState, prop, store}) => {
  const modalKey = `${inputId}:modal`;
  const modalOpen = store.getState("modal", modalKey);
  const modalRef = React.useRef();
  const service = filesService(store);
  const complete = !service.isIncomplete();
  const loading = service.anyFetchOngoing();
  const loadMoreDisabled = complete || loading;

  const input = React.useRef();
  const isUploading = service.isUploading();
  const handleLoadError = () =>
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "No se pudieron cargar los archivos; inténtelo más tarde",
      timeout: 5000,
    });
  const handleUploadError = (error) => {
    if (error instanceof errors.BodyTooLargeError) {
      TOAST_SHOWN.dispatch(store, {
        icon: "danger",
        text:
          "El archivo cargado es demasiado grande; " +
          "inténtelo con un archivo más pequeño",
        timeout: 5000,
      });
    } else {
      TOAST_SHOWN.dispatch(store, {
        icon: "danger",
        text:
          "Hubo un problema al cargar el archivo seleccionado; " +
          "intente con otro archivo o pruebe más tarde",
        timeout: 5000,
      });
    }
  };
  React.useEffect(() => {
    if (service.isOld() && !service.anyFetchOngoing()) {
      service.forceFetch().catch(handleLoadError);
    }
  });

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <div className="btn-toolbar" role="toolbar">
        <div className="btn-group mr-2" role="group">
          <button
            type="button"
            className="btn btn-light filefield-button"
            title="Seleccionar archivo del dispositivo"
            onClick={() => input.current.click()}
            disabled={isUploading}
          >
            <span>
              <i className="fas fa-paperclip"></i>
            </span>
          </button>
        </div>
        <div className="btn-group mr-2" role="group">
          <button
            type="button"
            className="btn btn-light filefield-button"
            title="Seleccionar archivo precargado"
            onClick={MODAL_SHOWN.dispatcher(store, modalKey)}
            disabled={isUploading}
          >
            <span>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </button>
        </div>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-light filefield-button"
            title="Quitar archivo"
            onClick={() => saveState(null)}
            disabled={isUploading}
          >
            <span>
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      </div>
      <input
        type="file"
        className="d-none"
        id={inputId}
        ref={input}
        onChange={(evt) =>
          evt.target.files.length > 0
            ? service
                .upload(evt.target.files[0])
                .then((resource) => saveState(resource), handleUploadError)
            : null
        }
        disabled={isUploading}
      />
      <FileDisplay store={store} resource={initialState} isLoading={isUploading} />
      <div
        className={["modal", modalOpen ? "fade show d-block" : null]
          .filter((c) => c)
          .join(" ")}
        tabIndex="-1"
        role="dialog"
        ref={modalRef}
        onClick={(e) =>
          e.target === modalRef.current ? MODAL_HIDDEN.dispatch(store, modalKey) : null
        }
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Seleccione un archivo</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loading && <Loader />}
              {/* Display the current thing's files always first */}
              {pristineState.map((resource) => (
                <FileDisplay
                  key={resource.id}
                  className="mb-2"
                  store={store}
                  resource={resource}
                  onClick={() => {
                    saveState(resource);
                    MODAL_HIDDEN.dispatch(store, modalKey);
                  }}
                />
              ))}
              {/* Then display all the currently, apparently unlinked files */}
              {(store.getState("resource", service.resourceKey(), "data", "items") ?? [])
                .map((resource) => ({
                  ...resource,
                  linked: isDefined(service.linkedThing(resource)),
                }))
                .filter(({linked}) => !linked)
                .filter(
                  ({id}) =>
                    pristineState.findIndex((resource) => resource.id === id) === -1
                )
                .map((resource) => (
                  <FileDisplay
                    key={resource.id}
                    className="mb-2"
                    store={store}
                    resource={resource}
                    onClick={() => {
                      saveState(resource);
                      MODAL_HIDDEN.dispatch(store, modalKey);
                    }}
                  />
                ))}
              {/* Show a "load more" button, or a warning that no files exist */}
              {store.getState("resource", service.resourceKey(), "data", "total") ===
              0 ? (
                <p className="lead mt-1 text-center">No hay archivos precargados.</p>
              ) : (
                <button
                  type="button"
                  className="btn btn-light btn-block btn-lg"
                  disabled={loadMoreDisabled}
                  onClick={() => service.fetch().catch(handleLoadError)}
                >
                  {complete ? "—" : "Cargar más"}
                </button>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-block btn-secondary"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </>
  );
};

export default (props) => <PluralField component={FileField} {...props} />;

import React from "react";

import AsyncComponent from "components/AsyncComponent";
import Loader from "components/Loader";
import FileDisplay from "components/file/FileDisplay";
import filesService from "services/files";
import {editThing} from "services/views";
import {TOAST_SHOWN} from "state/actions";
import * as fmt from "formatters";
import {groupBy} from "utils";

export default class FilesList extends AsyncComponent {
  get service() {
    return filesService(this.props.store);
  }

  get isLoading() {
    return this.service.anyFetchOngoing();
  }

  editThing(thing) {
    return () =>
      editThing(
        this.props.store,
        (this.props.namespace.schemas ?? []).find(({id}) => id === thing.schemaId),
        thing
      );
  }

  deleteFile(file) {
    return () => this.service.deleteFile(file).catch(this.async(this.handleDeleteError));
  }

  refresh() {
    const {onActivate} = this.props;
    onActivate();
    if (this.service.isOld() && !this.isLoading) {
      this.service
        .forceFetch()
        .then(this.async(onActivate), this.async(this.handleLoadError));
    }
  }

  extend(top = false) {
    const {onActivate} = this.props;
    if (top && !this.isLoading) {
      this.service
        .fetch(0)
        .then(this.async(onActivate), this.async(this.handleLoadError));
    } else if (this.service.isIncomplete() && !this.isLoading) {
      this.service.fetch().catch(this.async(this.handleLoadError));
    }
  }

  handleDeleteError() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "warning",
      text: "No se puede eliminar el archivo; es posible que esté siendo usado",
      timeout: 5000,
    });
  }

  handleLoadError() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "Hubo un problema al cargar los archivos; inténtelo más tarde",
      timeout: 5000,
    });
  }

  componentDidMount() {
    if (this.props.active) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.refresh();
    }
  }

  get sectionList() {
    const {store} = this.props;
    const files = (
      store.getState("resource", this.service.resourceKey(), "data", "items") || []
    ).map((file) => ({...file, linkedThing: this.service.linkedThing(file)}));
    return groupBy(files, (f) => f.createdAt.slice(0, 10)).map((items) => ({
      title: fmt.readableDatestring(items[0].createdAt),
      key: items[0].createdAt.slice(0, 10),
      items,
    }));
  }

  renderSectionHeader(section, isFirst) {
    return (
      <p className="mb-0 ml-3 mr-3">
        <small>
          <strong className="text-muted">{section.title}</strong>
        </small>
      </p>
    );
  }

  renderFile(file, isFirst, isLast) {
    const {linkedThing} = file;
    const schemaName = (
      (this.props.namespace.schemas ?? []).find((s) => s.id === linkedThing?.schemaId)
        ?.contents?.thingName ?? "<entidad>"
    ).toLowerCase();
    return (
      <div
        key={file.id}
        className={["mx-3 file-item", isFirst ? "border-top border-secondary" : null]
          .filter((c) => c)
          .join(" ")}
      >
        <FileDisplay store={this.props.store} resource={file} />
        <button
          type="button"
          className={["btn btn-block", linkedThing ? null : "delete"]
            .filter((c) => c)
            .join(" ")}
          title={linkedThing ? `Ir a ${schemaName}` : "Eliminar archivo"}
          onClick={
            linkedThing
              ? this.editThing(linkedThing).bind(this)
              : this.deleteFile(file).bind(this)
          }
          disabled={this.isLoading}
        >
          {linkedThing ? (
            <span className="text-truncate">
              <strong>En {linkedThing.logo ? "logotipo" : schemaName}:</strong>{" "}
              {linkedThing.logo
                ? linkedThing.label1
                : fmt.formatThing(this.props.namespace)(linkedThing)}
            </span>
          ) : (
            <>
              <i className="fas fa-trash-alt pr-2"></i> Eliminar archivo
            </>
          )}
        </button>
      </div>
    );
  }

  renderEmpty() {
    return <p className="lead mt-1 text-center">No hay archivos.</p>;
  }

  renderFooter() {
    const complete = !this.service.isIncomplete();
    const disabled = complete || this.isLoading;
    return (
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light btn-block btn-lg"
          disabled={disabled}
          onClick={() => this.extend(false)}
        >
          {complete ? "—" : "Cargar más"}
        </button>
      </div>
    );
  }

  render() {
    const {active} = this.props;
    if (!active) {
      return null;
    }
    const sections = this.sectionList;
    return (
      <>
        {this.isLoading && <Loader />}
        {sections.map((section, index) => (
          <React.Fragment key={section.key}>
            {this.renderSectionHeader(section, index === 0)}
            {section.items.map((item, index) =>
              this.renderFile(item, index === 0, index === section.items.length - 1)
            )}
          </React.Fragment>
        ))}
        {sections.length === 0 ? this.renderEmpty() : this.renderFooter()}
      </>
    );
  }
}

// Source:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const storageAvailable = (type) => {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export const available = storageAvailable("localStorage");

export const saveState = available
  ? (key, value) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        console.warn("Couldn't save state", e);
      }
    }
  : ((nothing) => () => nothing)();

export const readState = available
  ? ((nothing) => (key) => {
      try {
        const saved = localStorage.getItem(key);
        if (saved === null) {
          return nothing;
        }
        return JSON.parse(saved);
      } catch (e) {
        console.warn("Couldn't read state", e);
        return nothing;
      }
    })()
  : ((nothing) => () => nothing)();

import apiClient from "services/api";
import * as actions from "state/actions";

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});

  return {
    currentNamespace: () =>
      getState("config", "currentNamespace") ||
      getState("resource", "namespaces", "data", 0) || {name: "— Motas —"},

    setName: (name) =>
      client.put("namespaceName", "n/name", {body: {name}}).then((namespace) =>
        dispatch(
          actions.RESOURCE_ADDED.make({
            resource: "namespaces",
            data: getState("resource", "namespaces", "data").map((ns) =>
              ns.domain === namespace.domain ? {...ns, ...namespace} : ns
            ),
            timestamp: new Date(),
          }),
          actions.CONFIG_SET.make({
            currentNamespace: {
              ...getState("resource", "namespaces", "data").find(
                (ns) => ns.domain === namespace.domain
              ),
              ...namespace,
            },
          })
        )
      ),

    setNameOngoing: () => !!getState("fetch", "ongoing", "namespaceName"),
  };
};

import React from "react";

import icons from "components/icons";
import namespaceService from "services/namespaces";
import {isDefined} from "utils";
import * as conf from "conf";

const makeFileURI = (store, resourceLike) => {
  const currentDomain = namespaceService(store).currentNamespace().domain;
  if (!currentDomain) {
    return null;
  }
  return [
    conf.FILES_BASE.endsWith("/") ? conf.FILES_BASE.slice(0, -1) : conf.FILES_BASE,
    currentDomain,
    `${resourceLike.fileId}${resourceLike.extension}`,
  ].join("/");
};

const ARCHIVE_MIMETYPES = [
  "application/x-bzip",
  "application/x-bzip2",
  "application/x-gzip",
  "multipart/x-gzip",
  "application/x-compressed",
  "application/x-zip-compressed",
  "application/zip",
  "multipart/x-zip",
  "application/x-tar",
  "application/gnutar",
  "application/x-rar-compressed",
  "application/x-7z-compressed",
];

const EXCEL_MIMETYPES = [
  "application/excel",
  "application/x-excel",
  "application/x-msexcel",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const POWERPOINT_MIMETYPES = [
  "application/powerpoint",
  "application/mspowerpoint",
  "application/x-mspowerpoint",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const WORD_MIMETYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const iconForResourceKind = (kind) => {
  if (ARCHIVE_MIMETYPES.indexOf(kind) !== -1) {
    return icons.fileArchive;
  }
  if (kind.startsWith("audio/")) {
    return icons.fileAudio;
  }
  if (EXCEL_MIMETYPES.indexOf(kind) !== -1) {
    return icons.fileExcel;
  }
  if (kind === "application/pdf") {
    return icons.filePdf;
  }
  if (POWERPOINT_MIMETYPES.indexOf(kind) !== -1) {
    return icons.filePowerpoint;
  }
  if (kind.startsWith("video/")) {
    return icons.fileVideo;
  }
  if (WORD_MIMETYPES.indexOf(kind) !== -1) {
    return icons.fileWord;
  }
  return icons.fileAlt;
};

export default ({store, resource, isLoading, onClick, className}) => {
  if (isLoading) {
    return (
      <span
        className={["file-display text-muted", className].filter(isDefined).join(" ")}
        title="Cargando"
      >
        <i className="fas fa-spinner fa-pulse"></i>
      </span>
    );
  }
  if (!isDefined(resource)) {
    return (
      <span
        className={["file-display", className].filter(isDefined).join(" ")}
        title="No hay archivo"
      >
        <i className="fas fa-ban"></i>
      </span>
    );
  }
  if (resource.kind && resource.kind.startsWith("image")) {
    const imageResource =
      (resource.variants || []).find((variant) => variant.role === "thumbnail") ??
      resource;
    return (
      <span
        className={["file-display clickable", className].filter(isDefined).join(" ")}
        {...(onClick
          ? {onClick}
          : {onDoubleClick: () => window.open(makeFileURI(store, resource), "_blank")})}
      >
        <img
          src={makeFileURI(store, imageResource)}
          alt={`Imagen ${resource.extension.slice(1)}`}
        />
      </span>
    );
  }
  return (
    <span
      className={["file-display clickable", className].filter(isDefined).join(" ")}
      {...(onClick
        ? {onClick}
        : {onDoubleClick: () => window.open(makeFileURI(store, resource), "_blank")})}
    >
      {iconForResourceKind(resource.kind)}
    </span>
  );
};

import * as errors from "errors";
import apiClient from "services/api-client";
import workingSet from "services/working-set";
import * as actions from "state/actions";

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});

  return {
    login: (email, password) =>
      client
        .post("login", "u/bootstrap", {body: {email, password}})
        .then(({token, ...bootstrappedData}) =>
          dispatch(
            actions.TOKEN_RECEIVED.make(token),
            ...workingSet({getState, dispatch}).reloadActions(bootstrappedData)
          )
        ),

    loginOngoing: () => !!getState("fetch", "ongoing", "login"),

    logout: () =>
      client
        .post("logout", "u/logout")
        .catch(() => {
          // Ignore all errors produced by logout. Simply log out anyway.
        })
        .then(() =>
          dispatch(
            actions.TOKEN_HARD_EXPIRED.make(),
            actions.RESOURCE_PURGED.make({except: null}),
            actions.CONFIG_CLEAR.make(),
            actions.VIEW_RESET.make()
          )
        ),

    logoutOngoing: () => !!getState("fetch", "ongoing", "logout"),

    refresh: () =>
      client
        .post("refresh-token", "u/refresh")
        .then(({token}) => dispatch(actions.TOKEN_RECEIVED.make(token)))
        .catch((err) => {
          if (err instanceof errors.AuthenticationError) {
            dispatch(actions.TOKEN_HARD_EXPIRED.make());
          }
          return Promise.reject(err);
        }),

    refreshOngoing: () => !!getState("fetch", "ongoing", "refresh-token"),
  };
};

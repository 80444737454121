import React from "react";

const makeIcon = (c) => <i className={`fas fa-${c}`}></i>;

export default {
  arrowLeft: makeIcon("arrow-left"),
  ban: makeIcon("ban"),
  bars: makeIcon("bars"),
  calendarAlt: makeIcon("calendar-alt"),
  camera: makeIcon("camera"),
  check: makeIcon("check"),
  circle: makeIcon("circle"),
  clock: makeIcon("clock"),
  cog: makeIcon("cog"),
  cubes: makeIcon("cubes"),
  ellipsisV: makeIcon("ellipsis-v"),
  exclamationTriangle: makeIcon("exclamation-triangle"),
  fileAlt: makeIcon("file-alt"),
  fileArchive: makeIcon("file-archive"),
  fileAudio: makeIcon("file-audio"),
  fileExcel: makeIcon("file-excel"),
  filePdf: makeIcon("file-pdf"),
  filePowerpoint: makeIcon("file-powerpoint"),
  fileVideo: makeIcon("file-video"),
  fileWord: makeIcon("file-word"),
  key: makeIcon("key"),
  minusCircle: makeIcon("minus-circle"),
  moneyBillAlt: makeIcon("money-bill-alt"),
  paperclip: makeIcon("paperclip"),
  plusCircle: makeIcon("plus-circle"),
  plusCirclePrimary: makeIcon("plus-circle.primary"),
  search: makeIcon("search"),
  shareAltSquare: makeIcon("share-alt-square"),
  signOutAlt: makeIcon("sign-out-alt"),
  spinner: makeIcon("spinner"),
  times: makeIcon("times"),
  trashAlt: makeIcon("trash-alt"),
  userCircle: makeIcon("user-circle"),
};

import moment from "moment";
import "moment/locale/es";

const THOUSANDS_SEPARATOR = 3;

export const formatNumber = (v, {prefix, suffix, thousands, decimals}) => {
  let dec = v.toFixed(decimals);
  const sign = dec.startsWith("-") ? "-" : "";
  if (sign === "-") {
    dec = dec.slice(1);
  }
  while (dec.indexOf(".") !== -1 && (dec.endsWith("0") || dec.endsWith("."))) {
    dec = dec.slice(0, -1);
  }
  dec = dec.replace(/\./g, ",");
  if (thousands) {
    const [integer, fractional] = dec.split(",");
    dec = Array.from(integer)
      .reverse()
      .reduce(
        (acc, digit, index) => [
          ...acc,
          index > 0 && index % THOUSANDS_SEPARATOR === 0 ? "." : "",
          digit,
        ],
        []
      )
      .reverse()
      .join("");
    if (fractional) {
      dec += `,${fractional}`;
    }
  }
  return `${prefix}${sign}${dec}${suffix}`;
};

export const formatDate = (v, dateFormat) => {
  if (dateFormat) {
    return moment(v).locale("es").format(dateFormat);
  }
  return moment(v).locale("es").format("LL");
};

export const formatDatetime = (v, datetimeFormat) => {
  if (datetimeFormat) {
    return moment(v).locale("es").format(datetimeFormat);
  }
  return moment(v).locale("es").format("LLL");
};

const displayed = (prop, main) => {
  const inMain =
    typeof prop.displayedMain === "undefined" || prop.displayedMain === null
      ? prop.displayed
      : prop.displayedMain;
  if (main) {
    return inMain;
  }
  return prop.displayed;
};

export const formatThing = (namespace, brief = false, main = false) => (thing) => {
  const schema = namespace.schemas.find(({id}) => id === thing.schemaId);
  if (!schema) {
    return "";
  }
  let displayProps = schema.contents.thingProps.filter((prop) => displayed(prop, main));
  if (displayProps.length === 0) {
    displayProps = schema.contents.thingProps.filter(
      (prop) => prop.type === "text" && prop.required
    );
  }
  if (brief) {
    displayProps = displayProps.slice(0, 1);
  }
  if (displayProps.length === 0) {
    return schema.contents.thingName;
  }
  return displayProps
    .map((prop) => ({prop, value: thing.data[prop.key]}))
    .flatMap(({prop, value}) =>
      prop.singular ? [{prop, value}] : (value || []).map((v) => ({prop, value: v}))
    )
    .map(({prop, value}) => {
      switch (prop.type) {
        case "text":
          return (
            (value || "").split("\n").filter((piece) => Boolean(piece.trim()))[0] || ""
          );
        case "number":
          return value === null
            ? ""
            : prop.numberFormat
            ? formatNumber(value, prop.numberFormat)
            : value.toString();
        case "date":
          return value === null ? "" : formatDate(value, prop.dateFormat);
        case "datetime":
          return value === null ? "" : formatDatetime(value, prop.datetimeFormat);
        case "boolean":
          return value ? prop.propName : `No ${prop.propName.toLowerCase()}`;
        case "relation":
          return value && value.data ? formatThing(namespace, true)(value) : "";
        default:
          return "";
      }
    })
    .filter((piece) => Boolean(piece.trim()))
    .join(" — ");
};

export const datestring = (timestamp) => {
  const d = new Date(timestamp);
  return d.toJSON().slice(0, 10);
};

export const readableDatestring = (timestamp) => {
  const makeDate = (from, delta) => {
    const d = new Date(from);
    d.setDate(d.getDate() + delta);
    return [d.getFullYear(), d.getMonth(), d.getDate()];
  };
  const date = makeDate(timestamp, 0);
  const now = new Date();
  const today = makeDate(now, 0);
  const yesterday = makeDate(now, -1);
  const tomorrow = makeDate(now, 1);
  if (today.join("-") === date.join("-")) {
    return "Hoy";
  }
  if (yesterday.join("-") === date.join("-")) {
    return "Ayer";
  }
  if (tomorrow.join("-") === date.join("-")) {
    return "Mañana";
  }
  const [year, month, day] = date;
  const [thisYear] = today;
  if (year === thisYear) {
    return `${day} de ${moment.months()[month]}`;
  }
  return `${day} de ${moment.months()[month]} de ${year}`;
};

const padLeft = (s, pad, length) => {
  if (s.length >= length) {
    return s;
  }
  return padLeft(`${pad}${s}`, pad, length);
};

export const formatMachineDate = (d) =>
  [
    padLeft(`${d.getFullYear()}`, "0", 4),
    padLeft(`${d.getMonth() + 1}`, "0", 2),
    padLeft(`${d.getDate()}`, "0", 2),
  ].join("-");

export const formatMachineTime = (d) =>
  [
    padLeft(`${d.getHours()}`, "0", 2),
    padLeft(`${d.getMinutes()}`, "0", 2),
    padLeft(`${d.getSeconds()}`, "0", 2),
  ].join(":");

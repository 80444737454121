import React from "react";

import Form from "components/Form";
import * as errors from "errors";
import profile from "services/profile";
import {TOAST_SHOWN, VIEW_POPPED} from "state/actions";

const pristineState = {
  missingEmail: false,
  emailTaken: false,
  phoneTaken: false,
  networkError: false,
  serverError: false,
  name: "",
  email: "",
  phone: "",
};

export default class ProfileForm extends Form {
  state = {
    ...pristineState,
  };

  componentDidMount() {
    const {getState} = this.props.store;
    this.setState({
      name: getState("resource", "user", "data", "name") || "",
      email: getState("resource", "user", "data", "email"),
      phone: getState("resource", "user", "data", "phone"),
    });
  }

  changeProfile(e) {
    e.preventDefault();
    const {store} = this.props;
    const name = (this.controls.name.value || "").trim();
    const email = (this.controls.email.value || "").trim();
    const phone = (this.controls.phone.value || "").trim();
    if (!email) {
      return this.setState({
        ...pristineState,
        name,
        email,
        phone,
        missingEmail: true,
      });
    }
    this.setState({
      ...pristineState,
      name,
      email,
      phone,
    });
    profile(store)
      .changeProfile(name, email, phone)
      .then(this.async(this.handleSuccess), this.async(this.handleError));
  }

  handleSuccess() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "info",
      text: "Se guardaron los cambios",
    });
    VIEW_POPPED.dispatch(store);
  }

  handleError(error) {
    if (error instanceof errors.NetworkError) {
      this.setState({networkError: true});
    } else if (
      error instanceof errors.RequestError &&
      error.details.context.registeredEmail
    ) {
      this.setState({emailTaken: true});
    } else if (
      error instanceof errors.RequestError &&
      error.details.context.registeredPhone
    ) {
      this.setState({phoneTaken: true});
    } else {
      this.setState({serverError: true});
    }
  }

  render() {
    const formDisabled = profile(this.props.store).changeProfileOngoing();
    return (
      <form
        className="px-3 flex-grow-1 overflow-auto w-lg-50 w-md-75"
        disabled={formDisabled}
        onSubmit={this.changeProfile.bind(this)}
      >
        <div className="form-group">
          <label htmlFor="name">
            <strong>Tu nombre</strong>
          </label>
          <input
            className="form-control"
            type="text"
            name="name"
            id="name"
            placeholder="Tu nombre"
            ref={this.bindControl("name").bind(this)}
            disabled={formDisabled}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">
            <strong>Tu email</strong>
          </label>
          <input
            className="form-control"
            type="email"
            name="email"
            id="email"
            placeholder="Tu email"
            ref={this.bindControl("email").bind(this)}
            disabled={formDisabled}
          />
          {this.state.missingEmail && (
            <p className="form-text text-danger">Debes ingresar un email</p>
          )}
          {this.state.emailTaken && (
            <p className="form-text text-danger">
              Esta dirección le pertenece a otro usuario
            </p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="phone">
            <strong>Tu teléfono</strong>
          </label>
          <input
            className="form-control"
            type="tel"
            name="phone"
            id="phone"
            placeholder="Tu teléfono"
            ref={this.bindControl("phone").bind(this)}
            disabled={formDisabled}
          />
          {this.state.phoneTaken && (
            <p className="form-text text-danger">
              Esta número telefónico le pertenece a otro usuario
            </p>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block"
          disabled={formDisabled}
        >
          GUARDAR
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-lg btn-block"
          disabled={formDisabled}
          onClick={VIEW_POPPED.dispatcher(this.props.store)}
        >
          VOLVER
        </button>
        {this.state.networkError && (
          <p className="form-text text-danger">
            No se pudo establecer una conexión con el servidor
          </p>
        )}
        {this.state.serverError && (
          <p className="form-text text-danger">
            Ocurrió un error inesperado; inténtalo más tarde
          </p>
        )}
      </form>
    );
  }
}

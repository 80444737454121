import {Component} from "react";

class Cancellable {
  constructor(fn, bindTarget) {
    this.fn = fn;
    this.bindTarget = bindTarget;
    this.cancelled = false;
  }

  cancel() {
    this.cancelled = true;
    this.fn = null;
    this.bindTarget = null;
  }

  invoke(...args) {
    if (this.cancelled) {
      throw new Error("invocation cancelled");
    }
    return this.fn.call(this.bindTarget, ...args);
  }
}

export default class AsyncComponent extends Component {
  _subscriptions = [];

  async(fn) {
    const alreadySubscribed = this._subscriptions.find((c) => c.fn === fn);
    if (typeof alreadySubscribed !== "undefined") {
      return alreadySubscribed.invoke.bind(alreadySubscribed);
    }
    const cancellable = new Cancellable(fn, this);
    this._subscriptions.push(cancellable);
    return cancellable.invoke.bind(cancellable);
  }

  componentWillUnmount() {
    this._subscriptions.forEach((sub) => sub.cancel());
    this._subscriptions = [];
  }
}

import React from "react";

import {Plus} from "components/svg-icons";

export default ({onClick, disabled, hidden}) => (
  <div
    className={["add-new-button", hidden ? "d-none" : null].filter((c) => c).join(" ")}
  >
    <button
      type="button"
      className="btn btn-primary icon-button"
      onClick={onClick}
      title="Agregar nuevo"
      disabled={disabled}
    >
      <Plus />
    </button>
  </div>
);

import React from "react";

import NavBar from "components/layout/NavBar";
import Main from "components/layout/Main";
import Menu from "components/layout/Menu";
import SidePanel from "components/layout/SidePanel";

export default ({store}) => {
  const view = store.getState("router", "view");
  const props = {
    store,
    route: {
      view,
      args: store.getState("router", "args"),
    },
  };
  const showMenu =
    store.getState("config", "menu") ||
    (!props.route.view && !store.getState("resource", "namespaces", "data", "length"));
  const displaySidePanel =
    (store.getState("config", "sidePanel") || []).length > 0 && !props.route.view;
  return (
    <>
      <Menu shown={showMenu} {...props} />
      <div className="d-flex flex-row overflow-hidden h-100">
        <div
          className={[
            displaySidePanel ? "d-none d-sm-flex" : "d-flex",
            "flex-fill flex-column overflow-hidden",
            "main-panel",
          ].join(" ")}
        >
          <NavBar {...props} />
          <Main {...props} />
        </div>
        {displaySidePanel ? (
          <div
            className={[
              "d-flex",
              "flex-fill",
              "flex-column",
              "overflow-hidden",
              "border-left",
              "fixed-w-sm-45",
            ].join(" ")}
          >
            <SidePanel {...props} />
          </div>
        ) : null}
      </div>
    </>
  );
};

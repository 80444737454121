import React from "react";

import Form from "components/Form";
import SplashContainer from "components/splash/SplashContainer";
import SplashBanner from "components/splash/SplashBanner";
import * as errors from "errors";
import auth from "services/auth";

const pristineState = {
  missingEmail: false,
  missingPassword: false,
  invalidCredentials: false,
  networkError: false,
  serverError: false,
  email: "",
};

export default class Login extends Form {
  state = {
    ...pristineState,
  };

  attemptLogin(e) {
    e.preventDefault();
    const {store} = this.props;
    const email = (this.controls.email.value || "").trim();
    const password = this.controls.password.value;
    this.setState({...pristineState, email});
    if (!email || !password) {
      return this.setState({
        missingEmail: !email,
        missingPassword: !password,
      });
    }
    auth(store).login(email, password).catch(this.async(this.handleError));
  }

  handleError(error) {
    if (
      error instanceof errors.AuthenticationError ||
      error instanceof errors.RequestError
    ) {
      this.setState({invalidCredentials: true});
    } else if (error instanceof errors.NetworkError) {
      this.setState({networkError: true});
    } else {
      this.setState({serverError: true});
    }
  }

  render() {
    const formDisabled = auth(this.props.store).loginOngoing();
    return (
      <SplashContainer>
        <form disabled={formDisabled} onSubmit={this.attemptLogin.bind(this)}>
          <SplashBanner />
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder="Email"
              ref={this.bindControl("email").bind(this)}
              disabled={formDisabled}
            />
            {this.state.missingEmail && (
              <p className="form-text text-danger">Ingresa tu email</p>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="password"
              name="password"
              placeholder="Contraseña"
              ref={this.bindControl("password").bind(this)}
              disabled={formDisabled}
            />
            {this.state.missingPassword && (
              <p className="form-text text-danger">Ingresa tu contraseña</p>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block"
            disabled={formDisabled}
          >
            ENTRAR
          </button>
          {this.state.invalidCredentials && (
            <p className="form-text text-danger">
              El email o la contraseña son incorrectos
            </p>
          )}
          {this.state.networkError && (
            <p className="form-text text-danger">
              No se pudo establecer una conexión con el servidor
            </p>
          )}
          {this.state.serverError && (
            <p className="form-text text-danger">
              Ocurrió un error inesperado; inténtalo más tarde
            </p>
          )}
        </form>
      </SplashContainer>
    );
  }
}

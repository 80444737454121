import React from "react";

import namespaces from "services/namespaces";
import {MODAL_SHOWN, MODAL_HIDDEN} from "state/actions";
import * as fmt from "formatters";

export default ({store, schema, thing, onDelete, modalKey}) => {
  const modalOpen = store.getState("modal", modalKey);
  const modalRef = React.useRef();
  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-block btn-lg"
        onClick={MODAL_SHOWN.dispatcher(store, modalKey)}
      >
        ELIMINAR
      </button>
      <div
        className={["modal", modalOpen ? "fade show d-block" : null]
          .filter((c) => c)
          .join(" ")}
        tabIndex="-1"
        role="dialog"
        ref={modalRef}
        onClick={(e) =>
          e.target === modalRef.current ? MODAL_HIDDEN.dispatch(store, modalKey) : null
        }
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Eliminar {schema.contents.thingName.toLowerCase()}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Confirme que desea eliminar la/el{" "}
                {schema.contents.thingName.toLowerCase()}:
              </p>
              <p className="lead text-center">
                {fmt.formatThing(namespaces(store).currentNamespace())(thing)}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={MODAL_HIDDEN.dispatcher(store, modalKey)}
              >
                ME ARREPENTÍ
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  MODAL_HIDDEN.dispatch(store, modalKey);
                  onDelete();
                }}
              >
                SI, QUIERO ELIMINAR
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </>
  );
};

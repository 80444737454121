import apiClient from "services/api";
import * as actions from "state/actions";

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});

  return {
    changePassword: (oldPassword, newPassword) =>
      client.post("changePassword", "u/password", {
        body: {
          email: getState("resource", "user", "data", "email"),
          old: oldPassword,
          new: newPassword,
        },
      }),

    changePasswordOngoing: () => getState("fetch", "ongoing", "changePassword"),

    changeProfile: (name, email, phone) =>
      client
        .patch("changeProfile", "u", {
          body: {
            name,
            email,
            phone,
          },
        })
        .then((user) =>
          dispatch(
            actions.RESOURCE_ADDED.make({
              resource: "user",
              data: user,
              timestamp: new Date(),
            })
          )
        ),

    changeProfileOngoing: () => getState("fetch", "ongoing", "changeProfile"),
  };
};

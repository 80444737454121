import React from "react";

import auth from "services/auth";
import namespaceService from "services/namespaces";
import {
  CONFIG_SET,
  CONFIG_UNSET,
  VIEW_PUSHED,
  VIEW_RESET,
  FORM_STATE_PURGED_ALL,
} from "state/actions";

export default class Menu extends React.Component {
  state = {
    shown: false,
  };

  bindBackground(ref) {
    this.background = ref;
  }

  hideMenu(event) {
    if (event.target === this.background) {
      event.preventDefault();
      CONFIG_SET.dispatch(this.props.store, {menu: false});
    }
  }

  goToProfile(event) {
    CONFIG_SET.dispatch(this.props.store, {menu: false});
    if (this.props.route.view !== "profile") {
      VIEW_PUSHED.dispatch(this.props.store, {view: "profile"});
    }
  }

  goToPassword(event) {
    CONFIG_SET.dispatch(this.props.store, {menu: false});
    if (this.props.route.view !== "password") {
      VIEW_PUSHED.dispatch(this.props.store, {view: "password"});
    }
  }

  logout(event) {
    if (!this.props.store.getState("fetch", "ongoing", "logout")) {
      auth(this.props.store).logout();
    }
  }

  namespaceSelector(ns) {
    return (event) => {
      const namespace = namespaceService(this.props.store).currentNamespace();
      this.props.store.dispatch(
        CONFIG_SET.make({menu: false}),
        ...(ns.domain === namespace.domain
          ? []
          : [
              CONFIG_SET.make({currentNamespace: ns}),
              CONFIG_UNSET.make("currentSchema"),
              CONFIG_UNSET.make("sidePanel"),
              VIEW_RESET.make(),
              FORM_STATE_PURGED_ALL.make(),
            ])
      );
    };
  }

  goToNamespaceConfig(ns) {
    return (event) => {
      CONFIG_SET.dispatch(this.props.store, {menu: false});
      if (this.props.route.view !== "namespace-config") {
        VIEW_PUSHED.dispatch(this.props.store, {view: "namespace-config"});
      }
    };
  }

  componentDidMount() {
    this.setState({shown: this.props.shown});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shown && !this.props.shown) {
      setTimeout(() => this.setState({shown: false}), 500);
    } else if (!prevProps.shown && this.props.shown) {
      this.setState({shown: this.props.shown});
    }
  }

  renderHeader() {
    const name = this.props.store.getState("resource", "user", "data", "name");
    const email = this.props.store.getState("resource", "user", "data", "email");
    const leading = name || email;
    const trailing = name ? email : null;
    return (
      <>
        <h4 className="pt-3">
          {leading}
          {trailing ? <br /> : null}
          {trailing ? <small className="text-muted">{trailing}</small> : null}
        </h4>
      </>
    );
  }

  renderButton(text, icon, handler, active) {
    return (
      <button
        type="button"
        className={[
          "btn btn-light btn-block",
          "d-flex align-items-center",
          active ? "active" : null,
        ]
          .filter((c) => c)
          .join(" ")}
        onClick={handler}
      >
        <span className="text-left flex-grow-1">{text}</span>
        <i className={`fas fa-${icon}`} />
      </button>
    );
  }

  renderButtons() {
    const loggingOut = this.props.store.getState("fetch", "ongoing", "logout");
    return (
      <>
        {this.renderButton(
          "Perfil",
          "user-circle",
          this.goToProfile.bind(this),
          !loggingOut && this.props.route.view === "profile"
        )}
        {this.renderButton(
          "Cambio de contraseña",
          "key",
          this.goToPassword.bind(this),
          !loggingOut && this.props.route.view === "password"
        )}
        {this.renderButton(
          "Salir",
          "sign-out-alt",
          this.logout.bind(this),
          this.props.store.getState("fetch", "ongoing", "logout")
        )}
      </>
    );
  }

  renderNamespaces() {
    const namespace = namespaceService(this.props.store).currentNamespace();
    const namespaces = this.props.store.getState("resource", "namespaces", "data") || [];
    return (
      <>
        <p className="mb-0 small font-weight-bold">Dominios</p>
        <nav className="flex-grow-1 overflow-auto flex-column">
          {namespaces.map((ns) => (
            <div key={ns.domain} className="btn-group d-flex mb-1" role="group">
              <button
                type="button"
                className={[
                  "flex-grow-1",
                  "btn",
                  ns.domain === namespace.domain ? "btn-primary" : "btn-link",
                  "text-left",
                ].join(" ")}
                onClick={this.namespaceSelector(ns)}
              >
                {ns.name || ns.domain}
              </button>
              {ns.owned && ns.domain === namespace.domain ? (
                <button
                  type="button"
                  className="btn btn-primary flex-grow-0"
                  onClick={this.goToNamespaceConfig(ns)}
                  title="Configurar el dominio"
                >
                  <i className="fas fa-cog" />
                </button>
              ) : null}
            </div>
          ))}
          {namespaces.length === 0 ? (
            <p className="text-muted">No tienes dominios.</p>
          ) : null}
        </nav>
      </>
    );
  }

  render() {
    return (
      <div className={this.state.shown || this.props.shown ? "d-block" : "d-none"}>
        <div
          className={[
            "h-100",
            "w-100",
            "bg-dark",
            "fixed-top",
            this.props.shown ? "backdrop-in" : "backdrop-out",
          ].join(" ")}
        />
        <div className="container-fluid fixed-top h-100">
          <div
            className="row h-100 overflow-hidden"
            onClick={this.hideMenu.bind(this)}
            ref={this.bindBackground.bind(this)}
          >
            <div
              className={[
                "col",
                "col-lg-4",
                "col-md-5",
                "col-sm-6",
                "col-10",
                "bg-white",
                "h-100",
                "border-right",
                "border-light",
                "overflow-hidden",
                "d-flex",
                "flex-column",
                this.props.shown ? "slidein-right" : "slideout-left",
              ].join(" ")}
            >
              {this.renderHeader()}
              <hr className="mx-n3" />
              {this.renderButtons()}
              <hr className="mx-n3" />
              {this.renderNamespaces()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

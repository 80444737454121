import React from "react";

import PluralField from "components/thing/fields/PluralField";
import * as fmt from "formatters";
import {isDefined} from "utils";

const normalizeDate = (t) => new Date(`${t}T12:00:00`).toJSON();

const DateField = ({inputId, initialState, saveState, prop}) => {
  const input = React.useRef();
  const pending = React.useRef(true);
  React.useEffect(() => {
    if (input.current && pending.current) {
      input.current.value = initialState
        ? fmt.formatMachineDate(new Date(initialState))
        : initialState;
      pending.current = false;
    }
  });

  return (
    <>
      {prop.singular && (
        <label htmlFor={inputId}>
          <strong>{prop.propName}</strong>
        </label>
      )}
      <input
        type="date"
        id={inputId}
        className="form-control"
        required={prop.required}
        onChange={(evt) =>
          saveState(evt.target.value === "" ? null : normalizeDate(evt.target.value))
        }
        ref={input}
      />
      <small className="form-text text-muted">
        {isDefined(initialState) ? fmt.formatDate(initialState, prop.dateFormat) : ""}
      </small>
    </>
  );
};

export default (props) => <PluralField component={DateField} {...props} />;

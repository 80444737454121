import React from "react";

import Login from "components/auth/Login";
import Layout from "components/layout/Layout";
import Toast from "components/toast/Toast";
import SplashBanner from "components/splash/SplashBanner";
import SplashContainer from "components/splash/SplashContainer";
import auth from "services/auth";
import workingSet from "services/working-set";
import * as views from "services/views";
import {NOOP, TOAST_HIDDEN} from "state/actions";

export default class App extends React.Component {
  state = {
    initialized: false,
    storeState: null,
  };

  constructor(props) {
    super(props);
    this.unsubscribe = props.store.subscribe((storeState) => this.setState({storeState}));
  }

  escapeListener = (event) => {
    if (event.key === "Escape") {
      views.cancelAction(this.props.store);
    }
  };

  backListener = () => {
    if (views.cancelAction(this.props.store)) {
      // keep the dummy state to be able to intercept future events
      window.history.pushState({}, "");
    } else {
      // exit the app
      window.history.back();
    }
  };

  componentDidMount() {
    this.props.store.dispatch(NOOP.make());
    if (this.props.store.getState("auth", "token")) {
      auth(this.props.store)
        .refresh()
        .then(() =>
          workingSet(this.props.store).isOld()
            ? workingSet(this.props.store).reload()
            : null
        )
        .catch(() => {})
        .then(() => this.setState({initialized: true}));
    } else {
      this.setState({initialized: true});
    }
    document.addEventListener("keydown", this.escapeListener);
    // Set a dummy state to intercept 'back' events
    window.history.pushState({}, "");
    window.addEventListener("popstate", this.backListener);
  }

  componentWillUnmount() {
    this.unsubscribe();
    document.removeEventListener("keydown", this.escapeListener);
    window.removeEventListener("popstate", this.backListener);
  }

  render() {
    if (!this.state.initialized) {
      return (
        <SplashContainer>
          <SplashBanner />
        </SplashContainer>
      );
    }
    if (!this.props.store.getState("auth", "token")) {
      return <Login store={this.props.store} />;
    }
    return (
      <>
        <Toast
          {...this.props.store.getState("toast")}
          hide={TOAST_HIDDEN.dispatcher(this.props.store)}
        />
        <Layout store={this.props.store} />
      </>
    );
  }
}

import React from "react";

import AddNewButton from "components/namespace/AddNewButton";
import FilesList from "components/file/FilesList";
import RefreshButton from "components/namespace/RefreshButton";
import SchemaTabs from "components/namespace/SchemaTabs";
import SchemaThingList from "components/namespace/SchemaThingList";
import namespaces from "services/namespaces";
import schemas from "services/schemas";
import {peekSidePanel} from "services/views";

// Keep track of the scroll status.
// This forces all list components to declare an 'extend()' method
// which reacts to a data list extension request.
const sendScrollEvent = (() => {
  let timeout = null;
  let reachedEnd = false;
  return (area, target) => () => {
    reachedEnd =
      reachedEnd ||
      (area.current !== null &&
        area.current.scrollTop > 0 &&
        area.current.scrollHeight - area.current.scrollTop === area.current.clientHeight);
    if (timeout === null && reachedEnd) {
      timeout = setTimeout(() => {
        if (target.current !== null) {
          (target.current.extend || (() => {})).bind(target.current)();
        }
        setTimeout(() => {
          reachedEnd = false;
          timeout = null;
        }, 250);
      }, 250);
    }
  };
})();

export default (props) => {
  const namespaceService = namespaces(props.store);
  const schemaService = schemas(props.store);
  const schemaList = schemaService.schemas();
  const currentSchema = schemaService.currentSchema();
  const scrollArea = React.useRef(null);
  const listComponent = React.useRef(null);
  const onActivate = () =>
    setTimeout(() => {
      if (scrollArea.current) {
        scrollArea.current.scrollTo({top: 56, behaviour: "smooth"});
      }
    }, 500);
  return (
    <>
      <SchemaTabs
        store={props.store}
        schemas={schemaList}
        currentSchema={currentSchema}
        label={schemaService.schemaTabLabel}
        title={schemaService.schemaTabTitle}
      />
      <div
        className="schema-list-container"
        ref={scrollArea}
        onScroll={sendScrollEvent(scrollArea, listComponent)}
      >
        {/* This button expects each list component to declare an
          'extend(true)' method which refreshes the head of the
          list. It also expects them to define an isLoading property to
          enable or disable the button. */}
        <RefreshButton
          onClick={() =>
            (listComponent.current?.extend ?? (() => {})).bind(listComponent.current)(
              true
            )
          }
          disabled={listComponent.current?.isLoading ?? false}
        />
        {schemaList.map((schema, index) =>
          schema.files ? (
            <FilesList
              key={`schema-${schema.id}`}
              store={props.store}
              namespace={namespaceService.currentNamespace()}
              active={schema.id === currentSchema.id}
              onActivate={onActivate}
              {...(schema.id === currentSchema.id ? {ref: listComponent} : {})}
            />
          ) : (
            <SchemaThingList
              key={`schema-${schema.id}`}
              store={props.store}
              namespace={namespaceService.currentNamespace()}
              schema={schema}
              active={schema.id === currentSchema.id}
              onActivate={onActivate}
              {...(schema.id === currentSchema.id ? {ref: listComponent} : {})}
            />
          )
        )}
      </div>
      {/* This button expects each list component to declare an
          'add()' method which executes the transition into a form for
          adding elements to the list */}
      <AddNewButton
        onClick={() =>
          (listComponent.current?.add ?? (() => {})).bind(listComponent.current)()
        }
        disabled={(() => {
          const current = peekSidePanel(props.store);
          return (
            current?.action === "add-thing" && current?.schemaId === currentSchema.id
          );
        })()}
        hidden={currentSchema.files}
      />
    </>
  );
};

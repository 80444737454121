import React from "react";

import AsyncComponent from "components/AsyncComponent";
import Loader from "components/Loader";
import icons from "components/icons";
import {ReplyAll} from "components/svg-icons";
import thingsService from "services/things";
import {addThing, editThing} from "services/views";
import {TOAST_SHOWN} from "state/actions";
import * as aggregates from "aggregates";
import * as fmt from "formatters";
import {property, groupBy} from "utils";

export default class SchemaThingList extends AsyncComponent {
  get service() {
    return thingsService(this.props.store);
  }

  get isLoading() {
    return this.service.anyFetchOngoing(this.props.schema);
  }

  add() {
    addThing(this.props.store, this.props.schema);
  }

  edit(thing) {
    return () => editThing(this.props.store, this.props.schema, thing);
  }

  refresh() {
    const {schema, onActivate} = this.props;
    onActivate();
    if (this.service.isOld(schema) && !this.isLoading) {
      this.service
        .forceFetch(schema)
        .then(this.async(onActivate), this.async(this.handleError));
    }
  }

  extend(top = false) {
    const {schema, onActivate} = this.props;
    if (top && !this.isLoading) {
      this.service
        .fetch(schema, 0)
        .then(this.async(onActivate), this.async(this.handleError));
    } else if (this.service.isIncomplete(schema) && !this.isLoading) {
      this.service.fetch(schema).catch(this.async(this.handleError));
    }
  }

  handleError() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "Hubo un problema al cargar los datos; inténtelo más tarde",
      timeout: 5000,
    });
  }

  componentDidMount() {
    if (this.props.active) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.refresh();
    }
  }

  sectionLabel(thing) {
    const {contents} = this.props.schema;
    if (contents.thingSorter) {
      const mapFn = property(...contents.thingSorter);
      if (contents.thingSorter[0] === "data" && contents.thingSorter.length === 2) {
        const prop = contents.thingProps.find(({key}) => key === contents.thingSorter[1]);
        switch ((prop || {}).type) {
          case "text":
            return (mapFn(thing) || "").trim().toUpperCase()[0] || "?";
          case "number":
            return mapFn(thing).toString()[0] || "?";
          case "date":
          case "datetime":
            return fmt.readableDatestring(mapFn(thing));
          case "boolean":
            return mapFn(thing) ? `${prop.propName} (Sí)` : `${prop.propName} (No)`;
          default:
            break;
        }
      } else if (
        contents.thingSorter[0] === "createdAt" ||
        contents.thingSorter[0] === "updatedAt"
      ) {
        return fmt.readableDatestring(mapFn(thing));
      }
    }
    return fmt.readableDatestring(thing.updatedAt);
  }

  injectAggregates(sections) {
    const {schema} = this.props;
    if (!schema.contents.thingAggregator || sections.length === 0) {
      return sections;
    }
    const positions = aggregates.injectionPositions(sections, schema);
    return positions.flatMap((injectionPosition, index) => {
      let slice;
      let aggs;
      if (index < positions.length - 1) {
        slice = sections.slice(injectionPosition, positions[index + 1]);
        aggs = aggregates.makeAggregates(slice.flatMap(property("items")), schema);
      } else {
        slice = sections.slice(injectionPosition);
        if (!this.service.isIncomplete(schema)) {
          aggs = aggregates.makeAggregates(slice.flatMap(property("items")), schema);
        } else {
          aggs = [];
        }
      }
      return [...aggs, ...slice];
    });
  }

  get sectionList() {
    const {schema, store} = this.props;
    const things =
      store.getState("resource", this.service.resourceKey(schema), "data", "items") || [];
    return this.injectAggregates(
      groupBy(things, this.service.sortKey(schema)).map((items) => ({
        title: this.sectionLabel(items[0]),
        key: this.service.sortKey(schema)(items[0]),
        items,
      }))
    );
  }

  renderSectionHeader(section, isFirst) {
    return (
      <p className="mb-0 ml-3 mr-3">
        <small>
          <strong className="text-muted">{section.title}</strong>
        </small>
      </p>
    );
  }

  renderAggregate(aggregate, isFirst, isLast) {
    return (
      <div key={aggregate.id} className="thing-aggregate">
        <p
          className={[isFirst ? "first" : null, isLast ? "last" : null]
            .filter((c) => c)
            .join(" ")}
        >
          <span className="text-truncate boldy">{aggregate.label}:</span>
          <span className="text-truncate">{aggregate.value}</span>
        </p>
      </div>
    );
  }

  renderDeclaredThingIcon(thing) {
    const {
      contents: {thingIcons},
    } = this.props.schema;
    if (!thingIcons) {
      return null;
    }
    const selected = thingIcons
      .map(({icon, condition, color}) => {
        if (icons[icon] && aggregates.mapFn(condition)(thing)) {
          return {icon, color};
        }
        return null;
      })
      .find((image) => image !== null);
    if (!selected) {
      return null;
    }
    return selected.color
      ? React.cloneElement(icons[selected.icon], {style: {color: selected.color}})
      : icons[selected.icon];
  }

  renderThingIcon(thing) {
    const declared = this.renderDeclaredThingIcon(thing);
    if (thing.public) {
      return (
        <span className="text-nowrap">
          <span
            className={declared === null ? "" : "mr-1"}
            title="Compartido públicamente"
          >
            <ReplyAll />
          </span>
          {declared}
        </span>
      );
    }
    return declared;
  }

  renderThing(thing, isFirst, isLast) {
    const formatted = fmt.formatThing(this.props.namespace, false, true)(thing);
    return (
      <div className="thing-item" key={`thing-${thing.id}`}>
        <button
          type="button"
          className={[
            "btn",
            "btn-block",
            isFirst ? "first" : null,
            isLast ? "last" : null,
          ]
            .filter((c) => c)
            .join(" ")}
          title={formatted}
          onClick={this.edit(thing).bind(this)}
        >
          <span className="text-truncate">{formatted}</span>
          {this.renderThingIcon(thing)}
        </button>
      </div>
    );
  }

  renderEmpty() {
    const {
      schema: {contents},
    } = this.props;
    return (
      <p className="lead mt-1 text-center">
        No hay {(contents.thingNamePlural || `${contents.thingName}s`).toLowerCase()}.
      </p>
    );
  }

  renderFooter() {
    const complete = !this.service.isIncomplete(this.props.schema);
    const disabled = complete || this.isLoading;
    return (
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light btn-block btn-lg"
          disabled={disabled}
          onClick={() => this.extend(false)}
        >
          {complete ? "—" : "Cargar más"}
        </button>
      </div>
    );
  }

  render() {
    const {active} = this.props;
    if (!active) {
      return null;
    }
    const sections = this.sectionList;
    return (
      <>
        {this.isLoading && <Loader />}
        {sections.map((section, index) => (
          <React.Fragment key={section.key}>
            {this.renderSectionHeader(section, index === 0)}
            {section.items.map((item, index) =>
              item.aggregate
                ? this.renderAggregate(
                    item,
                    index === 0,
                    index === section.items.length - 1
                  )
                : this.renderThing(item, index === 0, index === section.items.length - 1)
            )}
          </React.Fragment>
        ))}
        {sections.length === 0 ? this.renderEmpty() : this.renderFooter()}
      </>
    );
  }
}

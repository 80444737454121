import apiClient from "services/api";
import namespaces from "services/namespaces";
import things from "services/things";
import * as actions from "state/actions";
import {chain, property, reverseSorter, mergeItems, isDefined} from "utils";

// The maximum age for the head of a collection of resources, in
// milliseconds
const MAXIMUM_AGE = 1000 * 60 * 60 * 8; // 8 hours

// Try to guess the extension from a filename
const getExtension = (name) => {
  if (!isDefined(name)) {
    return "";
  }
  const pieces = name.split(".");
  if (pieces.length === 1) {
    return "";
  }
  return pieces[pieces.length - 1];
};

export default ({getState, dispatch}) => {
  const client = apiClient({getState, dispatch});
  const namespaceService = namespaces({getState, dispatch});
  const thingsService = things({getState, dispatch});

  const resourceKey = () => `files(${namespaceService.currentNamespace().domain ?? ""})`;
  const uploadKey = () =>
    `uploadFile(${namespaceService.currentNamespace().domain ?? ""})`;
  return {
    resourceKey,

    isOld: () => {
      const ts = getState("resource", resourceKey(), "timestamp");
      return (
        typeof ts === "undefined" ||
        new Date().getTime() - new Date(ts).getTime() > MAXIMUM_AGE
      );
    },

    isIncomplete: () => {
      const total = getState("resource", resourceKey(), "data", "total");
      return (
        typeof total === "undefined" ||
        total > (getState("resource", resourceKey(), "data", "items", "length") || 0)
      );
    },

    forceFetch: () =>
      client.get(resourceKey(), "f").then(({total, items}) =>
        dispatch(
          actions.RESOURCE_ADDED.make({
            resource: resourceKey(),
            data: {total, items: items.sort(reverseSorter(property("createdAt")))},
            timestamp: new Date(),
          })
        )
      ),

    fetch: (givenOffset) => {
      const offset =
        typeof givenOffset === "undefined"
          ? getState("resource", resourceKey(), "data", "items", "length") || 0
          : givenOffset;
      return client.get(resourceKey(), "f", {params: {offset}}).then(({total, items}) => {
        const currentResource = getState("resource", resourceKey());
        return dispatch(
          actions.RESOURCE_ADDED.make({
            resource: resourceKey(),
            data: {
              total,
              items: mergeItems(
                chain(currentResource, "data", "items") || [],
                items
              ).sort(reverseSorter(property("createdAt"))),
            },
            timestamp: chain(currentResource, "timestamp") || new Date(),
          })
        );
      });
    },

    linkedThing: (file) => {
      const currentNamespace = namespaceService.currentNamespace();
      if (currentNamespace.logo?.id === file.id) {
        return {logo: true, label: currentNamespace.name ?? currentNamespace.domain};
      }
      const fileProps = (
        currentNamespace.schemas ?? []
      ).flatMap(({id, contents: {thingProps}}) =>
        thingProps
          .filter(({type}) => type === "file")
          .map((prop) => ({schema: {id}, prop}))
      );
      return fileProps
        .map(({schema, prop}) =>
          (
            getState("resource", thingsService.resourceKey(schema), "data", "items") ?? []
          ).find((thing) =>
            (prop.singular ? [thing.data[prop.key] ?? {}] : thing.data[prop.key] ?? [])
              .filter(isDefined)
              .some(({id}) => id === file.id)
          )
        )
        .find((t) => t);
    },

    deleteFile: (file) =>
      client.delete("deleteFile", `f/${file.id}`).then(() => {
        const currentResource = getState("resource", resourceKey(), "data");
        if (currentResource) {
          const items = (currentResource.items ?? []).filter(({id}) => id !== file.id);
          dispatch(
            actions.RESOURCE_ADDED.make({
              resource: resourceKey(),
              data: {
                total:
                  items.length === (currentResource.items?.length ?? 0)
                    ? currentResource.total
                    : currentResource.total - 1,
                items,
              },
              timestamp: chain(currentResource, "timestamp") || new Date(),
            })
          );
        }
      }),

    anyFetchOngoing: () =>
      !!getState("fetch", "ongoing", resourceKey()) ||
      !!getState("fetch", "ongoing", "deleteFile"),

    uploadKey,

    upload: (file) =>
      client
        .postBlob(uploadKey(), "f", {file, params: {ext: getExtension(file.name)}})
        .then((resource) => {
          const currentResource = getState("resource", resourceKey(), "data");
          const items = mergeItems(currentResource?.items ?? [], [resource]).sort(
            reverseSorter(property("createdAt"))
          );
          if (currentResource) {
            dispatch(
              actions.RESOURCE_ADDED.make({
                resource: resourceKey(),
                data: {
                  total:
                    items.length === (currentResource.items?.length ?? 0)
                      ? currentResource.total
                      : currentResource.total + 1,
                  items,
                },
                timestamp: chain(currentResource, "timestamp") || new Date(),
              })
            );
          }
          return resource;
        }),

    isUploading: () => !!getState("fetch", "ongoing", uploadKey()),
  };
};

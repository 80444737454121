import React from "react";

// Make a unique-key, value pair each time it's invoked
const withKey = (() => {
  let current = 1;
  return (value) => ({key: `plural-key:${current++}`, value});
})();

export default ({component, inputId, initialState, saveState, prop, ...props}) => {
  if (prop.singular) {
    return React.createElement(component, {
      inputId,
      initialState,
      saveState,
      prop,
      ...props,
    });
  }
  const [values, setValues] = React.useState(() => (initialState ?? []).map(withKey));
  const forwardState = () => saveState(values.map(({value}) => value));
  React.useEffect(forwardState, [values]);
  return (
    <>
      {prop.type !== "boolean" && (
        <label>
          <strong>
            {prop.propNamePlural || prop.propName}
            {values.length > 2 ? ` (${values.length})` : null}
          </strong>
        </label>
      )}
      {values.map(({key, value}) => {
        const itemProps = {
          ...props,
          inputId: `${inputId}:${key}`,
          initialState: value,
          saveState: (v) =>
            setValues((keyedValues) =>
              keyedValues.map((kv) => (kv.key === key ? {key, value: v} : kv))
            ),
          prop,
        };
        return (
          <div
            key={key}
            className="d-flex justify-content-between align-items-start mb-1"
          >
            <div className="flex-grow-1 mr-1">
              {React.createElement(component, itemProps)}
            </div>
            <button
              type="button"
              className="btn btn-dark icon-button-small"
              title={`Eliminar ${prop.propName.toLowerCase()}`}
              onClick={() =>
                setValues((keyedValues) => keyedValues.filter((kv) => key !== kv.key))
              }
            >
              <strong>−</strong>
            </button>
          </div>
        );
      })}
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-dark icon-button-small"
          title={`Agregar ${prop.propName.toLowerCase()}`}
          onClick={() => setValues((keyedValues) => [...keyedValues, withKey(null)])}
        >
          <strong>＋</strong>
        </button>
      </div>
    </>
  );
};

import {chain} from "utils";

// Makes a standard reducer from patterns of
// action -> result | function
export const makeReducer = (initial, patterns) => (state, action) => {
  const matched = patterns[action.type];
  const currentState = typeof state === "undefined" ? initial : state;
  if (typeof matched === "undefined") {
    return currentState;
  }
  return typeof matched === "function" ? matched(currentState, action.payload) : matched;
};

// Combines a dictionary of reducers into a single reducer
export const combineReducers = (reducers) => {
  const flat = Object.entries(reducers)
    .map(([key, reducer]) => [
      key,
      typeof reducer === "function" ? reducer : combineReducers(reducer),
    ])
    .reduce((partial, [k, reducer]) => ({...partial, [k]: reducer}), {});
  return (state, action) =>
    Object.entries(flat).reduce(
      (partialState, [key, reducer]) => ({
        ...partialState,
        [key]: reducer((state || {})[key], action),
      }),
      {}
    );
};

// Creates a store from the given single reducer and an initial state
export const createStore = (reducer, initialState) => {
  let state = initialState;
  let subscriptions = [];

  const dispatch = (...actions) => {
    state = actions.reduce(
      (partialState, action) => reducer(partialState, action),
      state
    );
    subscriptions.forEach((fn) => fn(state));
  };

  const subscribe = (fn) => {
    subscriptions.push(fn);
    return () => {
      subscriptions = subscriptions.filter((sub) => sub !== fn);
    };
  };

  const subscribeForProps = (props, fn) =>
    (() => {
      const makeSignature = (s) =>
        props.map((prop) =>
          prop.split(".").reduce((partial, p) => (partial || {})[p], s)
        );
      let signature = makeSignature(state);
      return subscribe((s) => {
        const newSignature = makeSignature(s);
        if (newSignature.some((value, index) => value !== signature[index])) {
          fn(s);
        }
        signature = newSignature;
      });
    })();

  const getState = (...props) => chain(state, ...props);

  return {dispatch, getState, subscribe, subscribeForProps};
};

import {
  CONFIG_SET,
  CONFIG_UNSET,
  NOOP,
  VIEW_POPPED,
  FORM_STATE_PURGED,
  FORM_STATE_PURGED_ALL,
  MODAL_HIDDEN_ALL,
} from "state/actions";

const equalSpecs = (s1, s2) => Object.entries(s1).every(([k, v]) => s2[k] === v);

export const peekSidePanel = (store) => (store.getState("config", "sidePanel") || [])[0];

export const pushSidePanel = (store, spec) => {
  const stack = store.getState("config", "sidePanel") || [];
  if (stack.length === 0 || !equalSpecs(spec, stack[0])) {
    CONFIG_SET.dispatch(store, {sidePanel: [spec, ...stack]});
  }
};

export const popSidePanelActions = (store) => {
  const stack = store.getState("config", "sidePanel") ?? [];
  if (stack.length > 1) {
    const {formStateKey} = stack[0];
    return [
      CONFIG_SET.make({sidePanel: stack.slice(1)}),
      ...(formStateKey ? [FORM_STATE_PURGED.make({key: formStateKey})] : []),
    ];
  } else {
    return [CONFIG_UNSET.make("sidePanel"), FORM_STATE_PURGED_ALL.make()];
  }
};

export const popSidePanel = (store) => store.dispatch(...popSidePanelActions(store));

export const addThing = (store, schema, targetFormStateKey = null) =>
  pushSidePanel(store, {
    action: "add-thing",
    schemaId: schema.id,
    formStateKey: `add-thing:${schema.id}:${new Date().getTime()}`,
    targetFormStateKey,
  });

export const editThing = (store, schema, thing) =>
  pushSidePanel(store, {
    action: "edit-thing",
    schemaId: schema.id,
    thing,
    formStateKey: `edit-thing:${schema.id}:${thing.id}`,
  });

export const editSidePanel = (store, modifier) => {
  const stack = store.getState("config", "sidePanel") ?? [];
  if (stack.length > 0) {
    CONFIG_SET.dispatch(store, {sidePanel: [modifier(stack[0]), ...stack.slice(1)]});
  }
};

export const transformSidePanelIntoAddThing = (store) =>
  editSidePanel(store, (spec) =>
    spec.action === "edit-thing"
      ? {
          action: "add-thing",
          schemaId: spec.schemaId,
          formStateKey: spec.formStateKey,
        }
      : spec
  );

export const cancelAction = (store) => {
  if (!store.getState("auth", "token")) {
    NOOP.dispatch(store);
    return false;
  }
  if (Object.keys(store.getState("modal")).length > 0) {
    MODAL_HIDDEN_ALL.dispatch(store);
  } else if (store.getState("config", "menu")) {
    CONFIG_SET.dispatch(store, {menu: false});
  } else if (store.getState("router", "stack", "length")) {
    VIEW_POPPED.dispatch(store);
  } else if (store.getState("config", "sidePanel")) {
    popSidePanel(store);
  } else {
    CONFIG_SET.dispatch(store, {menu: true});
  }
  return true;
};

export const titleForView = (store, view) =>
  ({
    "profile": "Perfil",
    "password": "Cambio de contraseña",
    "namespace-config": "Configuración de dominio",
  }[view] || view);

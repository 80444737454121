import React from "react";

import Form from "components/Form";
import * as errors from "errors";
import namespaces from "services/namespaces";
import {TOAST_SHOWN, VIEW_POPPED} from "state/actions";

const pristineState = {
  networkError: false,
  serverError: false,
  name: "",
};

export default class NamespaceConfig extends Form {
  state = {
    ...pristineState,
  };

  componentDidMount() {
    const {store} = this.props;
    this.setState({
      name: namespaces(store).currentNamespace().name,
    });
  }

  changeName(e) {
    e.preventDefault();
    const {store} = this.props;
    const name = (this.controls.name.value || "").trim();
    this.setState({...pristineState, name});
    namespaces(store)
      .setName(name)
      .then(this.async(this.handleSuccess), this.async(this.handleError));
  }

  handleSuccess() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "info",
      text: "Se guardaron los cambios",
    });
    VIEW_POPPED.dispatch(store);
  }

  handleError(error) {
    if (error instanceof errors.NetworkError) {
      this.setState({networkError: true});
    } else {
      this.setState({serverError: true});
    }
  }

  render() {
    const service = namespaces(this.props.store);
    const nameFormDisabled = service.setNameOngoing();
    return (
      <form
        className="px-3 flex-grow-1 overflow-auto w-lg-50 w-md-75"
        disabled={nameFormDisabled}
        onSubmit={this.changeName.bind(this)}
      >
        <div className="form-group">
          <label htmlFor="namespace-name">
            <strong>Nombre del dominio</strong>
          </label>
          <input
            className="form-control"
            type="text"
            name="namespace-name"
            id="namespace-name"
            placeholder="Nombre del dominio"
            ref={this.bindControl("name").bind(this)}
            disabled={nameFormDisabled}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block"
          disabled={nameFormDisabled}
        >
          GUARDAR
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-lg btn-block"
          disabled={nameFormDisabled}
          onClick={VIEW_POPPED.dispatcher(this.props.store)}
        >
          VOLVER
        </button>
        {this.state.networkError && (
          <p className="form-text text-danger">
            No se pudo establecer una conexión con el servidor
          </p>
        )}
        {this.state.serverError && (
          <p className="form-text text-danger">
            Ocurrió un error inesperado; inténtalo más tarde
          </p>
        )}
      </form>
    );
  }
}

import React from "react";

export const Switch = ({variable, defaultCase, children, ...props}) => {
  if (typeof variable === "undefined") {
    return React.createElement(defaultCase, props);
  }
  const child = React.Children.toArray(children).find((c) => c.props.value === variable);
  if (typeof child === "undefined") {
    console.warn(`Variable '${variable}' doesn't have a matching case`);
    return null;
  }
  if (typeof child.props.component === "undefined") {
    console.warn(`Matching case '${variable}' doesn't define a component property`);
    return null;
  }
  return React.createElement(child.props.component, props);
};

export const Case = ({value, component}) => null;

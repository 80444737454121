import React from "react";

import namespaceService from "services/namespaces";
import {pushSidePanel, titleForView} from "services/views";
import {CONFIG_SET, VIEW_POPPED} from "state/actions";

const navbarTitle = (store, route) => {
  const namespace = namespaceService(store).currentNamespace();
  if (!route.view) {
    return namespace.name || namespace.domain;
  }
  return titleForView(store, route.view);
};

const GoBackButton = ({store}) => (
  <button
    className="btn btn-light"
    type="button"
    aria-label="Volver"
    onClick={VIEW_POPPED.dispatcher(store)}
    title="Volver"
  >
    <i className="fas fa-arrow-left" />
  </button>
);

const MenuButton = ({store}) => (
  <button
    className="btn btn-light"
    type="button"
    aria-label="Activar menú"
    onClick={CONFIG_SET.dispatcher(store, {menu: true})}
    title="Activar menú"
  >
    <i className="fas fa-bars" />
  </button>
);

const openSearch = (store) => (event) => pushSidePanel(store, {action: "search"});

export default (props) => {
  return (
    <>
      <div className="d-flex bg-light justify-content-start align-items-center p-2">
        {props.route.view ? (
          <GoBackButton store={props.store} />
        ) : (
          <MenuButton store={props.store} />
        )}
        <span className="pl-2 mb-0 text-truncate flex-grow-1 h4">
          {navbarTitle(props.store, props.route)}
        </span>
        {props.route.view ? null : (
          <button
            className="btn btn-light"
            type="button"
            aria-label="Buscar"
            onClick={openSearch(props.store)}
            title="Buscar"
          >
            <i className="fas fa-search" />
          </button>
        )}
      </div>
    </>
  );
};

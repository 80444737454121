import React from "react";

import namespaceService from "services/namespaces";

export default ({store, spec}) => {
  const {
    contents: {thingName},
  } = (namespaceService(store).currentNamespace().schemas ?? []).find(
    ({id}) => id === spec.schemaId
  ) ?? {contents: {thingName: "<desconocido>"}};
  return (
    <span className="text-truncate">
      {spec.action === "add-thing" ? "Agregar" : "Editar"} {thingName.toLowerCase()}
    </span>
  );
};

import React from "react";

import AsyncComponent from "components/AsyncComponent";
import things from "services/things";
import {TOAST_SHOWN} from "state/actions";

export default class SearchBar extends AsyncComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  get service() {
    return things(this.props.store);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.input.current) {
        this.input.current.value =
          this.props.store.getState(
            "resource",
            this.service.searchKey(),
            "data",
            "phrase"
          ) ?? "";
        this.input.current.focus();
        this.input.current.select();
      }
    }, 250);
  }

  get isSearching() {
    return this.service.searchOngoing();
  }

  handleError() {
    const {store} = this.props;
    TOAST_SHOWN.dispatch(store, {
      icon: "danger",
      text: "Hubo un problema al buscar; inténtelo más tarde",
      timeout: 5000,
    });
  }

  search(e) {
    e.preventDefault();
    const phrase = (this.input.current?.value ?? "").trim();
    this.service.clearSearch();
    if (phrase.length > 0) {
      this.service.search(phrase).catch(this.async(this.handleError));
    }
  }

  render() {
    return (
      <form
        className="input-group"
        disabled={this.isSearching}
        onSubmit={this.search.bind(this)}
      >
        <input
          type="text"
          className="form-control"
          name="phrase"
          placeholder="Buscar..."
          title="Buscar"
          ref={this.input}
          disabled={this.isSearching}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="submit"
            disabled={this.isSearching}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
      </form>
    );
  }
}
